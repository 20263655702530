import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { CYBER_CELL_URL } from '../Process';
import Swal from 'sweetalert2';

import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

const ChangePasswordModal = ({openModel,setOpenModel,UserType,id}) => {
  const navigate=useNavigate()
    const [newPassword, setNewPassword] = useState('');
    const [cookies, setCookie, removeCookie] = useCookies();
  
    const handleChangePassword = async() => {
      const passObj={
        UserType:UserType,
        Password:newPassword
      }
      if(UserType=="Hotel"){
        passObj.HotelID=id
      }else if(UserType=="Restaurant"){
        passObj.RestaurantID=id
      }
      // Implement your logic to change the password
      // For example, send a request to your server to update the password
      try{
      console.log('Changing password to:', newPassword);
      const res=await fetch(`${CYBER_CELL_URL}/Common/ChangePassword`,{
        method:"post",
        headers:{
          "Content-Type":"application/json"
        },
        body:JSON.stringify({
          ...passObj
        })
      })

      const result=await res.json()
      console.log(result,"result")
      if(result.status==true){
       await Swal.fire({
          title: "Password Updated Successfully",
          icon: "success",
          timer: 1500,
        });
     
      }else{
         Swal.fire({
          title: result?.message || "Sorry! Could Not Change Password " ,
          icon: "error",
          timer: 1500,
        });
      }
      
      // Close the modal after changing the password
    }catch(err){
      console.log(err)
    }
    };
  return (
    <Modal show={openModel}  centered>
      <Modal.Header >
        <Modal.Title>Change Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="newPassword">
            <Form.Label>New Password:</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter your new password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={()=>setOpenModel(false)}>
          Close
        </Button>
        <Button variant="primary" 
        // onClick={handleChangePassword}
        >
          Change Password
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ChangePasswordModal