import React, { useEffect, useState } from "react";
import { CYBER_CELL_URL } from "../Process";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { FaCircleDot } from "react-icons/fa6";
import Select from "react-select";
import EyeOpen from "../Assets/eyeopen.png";
import EyeClose from "../Assets/eyeclose.png";

import { RotatingLines } from "react-loader-spinner";
import Swal from "sweetalert2";
import moment from "moment";
import { FraudHappeningMost } from "../Constants";

const MostFraudReports = () => {
  const [data, setData] = useState([]);
  const [pageId, setPageId] = useState(1);
  const [mainTableData, setMainTableData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState();
  const [pageCount, setPageCount] = useState(10);
  const [tableData, setTableData] = useState([]);
  const [policaStationData, setPolicaStationData] = useState([]);
  const [inputVal, setInputValue] = useState("");
  const [filterData, setFilterdata] = useState({
    PoliceStationName: "",
    PoliceStationTempt: "",
  });
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    fetchMostFraudReportsData();
  }, []);
  const fetchMostFraudReportsData = async () => {
    setIsLoading(true);
    try {
      const res = await fetch(
        `${CYBER_CELL_URL}/report/numbers_frauds_happening_most`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}),
        }
      );
      const result = await res.json();

      if (result.status == true && result.data) {
        const tempt = [];
        result.data.forEach((element) => {
          tempt.push({
            obj: element,
            SocialMediaIsDetails: false,
            Fraud1930IsDetails: false,
            RansomwareIsDetails: false,
            SocialMediaArr: [],
            Fraud1930Arr: [],
            RansomwareArr: [],
          });
        });

        console.log(result, "result");
        setData(tempt);
        setTotalCount(tempt.length);
        setMainTableData(tempt);
        setTableData(tempt.slice(0, pageCount));
        setPolicaStationData(
          tempt.map((item) => ({
            value: item.obj.mobile_number,
            label: item.obj.mobile_number,
          }))
        );
        setIsLoading(false);
      } else {
        return Swal.fire({
          title: result.message || "",
          icon: "error",
          timer: 1500,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const selectDropDownData1 = (e, action) => {
    setFilterdata({
      [action.name]: e.value,
      PoliceStationTempt: e,
    });
  };
  const handleNextPage = () => {
    console.log(data, "data");
    if (tableData.length < pageCount) {
      return;
    }
    const temptData = data.slice(
      pageId * pageCount,
      pageId * pageCount + pageCount
    );
    setTableData(temptData);
    setPageId(pageId + 1);
  };
  const handlePreviousPage = () => {
    if (pageId == 1) {
      return;
    }
    const temptData = data.slice(
      (pageId - 2) * pageCount,
      (pageId - 2) * pageCount + pageCount
    );
    setTableData(temptData);
    setPageId(pageId - 1);
  };
  const handlePageCount = (e) => {
    console.log(e.target.value, "e.target.value");
    setPageCount(parseInt(e.target.value));
    searchHandle(parseInt(e.target.value));
  };
  const searchHandle = (pageCount) => {
    const filterdata = mainTableData.filter((element, index) => {
      return element.obj.mobile_number.includes(
        filterData.PoliceStationTempt.value || ""
      );
    });
    setData(filterdata);
    setTableData(filterdata.slice(0, pageCount));
    setPageId(1);
    setPageCount(pageCount);
  };
  const handleCancle = () => {
    setTableData(mainTableData.slice(0, pageCount));
    setData(mainTableData);
    setPageId(1);
    setFilterdata({
      PoliceStationName: "",
      PoliceStationTempt: "",
    });
  };
  const handleInputChange = (e) => {
    setInputValue(e);
    searchHandle(e);
  };
  const handleEyeOnFetch =async(path,number)=>{
    try {
      const res = await fetch(
        `${CYBER_CELL_URL}/${path}`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            "mobile_number" : number
          }),
        }
      );
      const result = await res.json();

      if (result.status == true && result.data) {
      return result.data
      }
    }catch(err){
      return []
    }
  }
  const handleEyeOn = async(index, isDetails,isDetailsArr,path,number) => {
    console.log("eyeon");
    const tempt = [...tableData];
    if(!tempt[index][isDetailsArr].length){
      const finalRes=await handleEyeOnFetch(path,number)
      tempt.splice(index, 1, {
        ...tableData[index],
        SocialMediaIsDetails: false,
        Fraud1930IsDetails: false,
        RansomwareIsDetails: false,
        [isDetailsArr] :finalRes,
        [isDetails]: true
      });
      console.log(finalRes,"finalRes")

    }else{
    tempt.splice(index, 1, {
      ...tableData[index],
      SocialMediaIsDetails: false,
      Fraud1930IsDetails: false,
      RansomwareIsDetails: false,
      [isDetails]: true,
    });
  }
    setTableData(tempt);
  };
  const handleEyeOff = (index, isDetails) => {
    const tempt = [...tableData];
    tempt.splice(index, 1, {
      ...tableData[index],
      SocialMediaIsDetails: false,
      Fraud1930IsDetails: false,
      RansomwareIsDetails: false,
      [isDetails]: false,
    });
    setTableData(tempt);
  };
  return (
    <div className="body-wrapper">
      <div className="container-fluid">
        {isLoading && (
          <div
            style={{
              position: "absolute",
              zIndex: "6",
              left: "600px",
              top: "300px",
            }}
          >
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="200"
              height="200"
              visible={true}
            />
          </div>
        )}
       <h4 className="float-left">{FraudHappeningMost}</h4>
        <div>
          <div
            className={` pt-2 pb-2 place-filter  ${isOpen ? "open" : "closed"}`}
          >
            <Row>
              <Col md={2} sm={2} className="col-4">
                <div className="d-flex gap-2 pt-1">
                  <span>Total Count :</span>
                  <span>{totalCount}</span>
                </div>
              </Col>
              <Col md={2} sm={2} className="col-4">
                <Form.Select
                  onChange={handlePageCount}
                  size="sm"
                  value={pageCount}
                  className="w-70"
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                </Form.Select>
              </Col>
              <Col md={{ span: 2, offset: 6 }} className="col-4">
                <Button
                  className="float-end"
                  md={2}
                  variant="outline-primary"
                  size="sm"
                  onClick={handleToggle}
                >
                  {isOpen ? "Close Filter" : "Open Filter"}
                </Button>
              </Col>
            </Row>
          </div>
          {isOpen && (
            <div
              className="filter-content"
              style={{
                width: "100%",
                boxShadow: "2px 1px 15px",
                padding: "20px",
                backgroundColor: "white",
                borderRadius: "5px",
                textAlign: "center",
                margin: "2em auto",
                border: "1px solid gray",
              }}
            >
              <Form className="d-flex gap-4 ">
                <div className="form-group col-md-3 mb-2">
                  <label
                    htmlFor="PoliceStationName"
                    className="form-label fw-semibold"
                  >
                    {" "}
                    Mobile Number
                  </label>
                  <Select
                    name="PoliceStationName"
                    placeholder="--Select Mobile Number--"
                    onChange={selectDropDownData1}
                    value={filterData.PoliceStationTempt}
                    options={[
                      { label: "Select Mobile Number ", value: "" },
                      ...policaStationData,
                    ]}
                  />
                </div>

                <div
                  className="pb-3"
                  style={{ position: "relative", top: "27px" }}
                >
                  <Button
                    className="me-2 mb-2"
                    onClick={() => searchHandle(pageCount)}
                    variant="success"
                  >
                    Apply
                  </Button>
                  <Button
                    className="me-2 mb-2"
                    onClick={handleCancle}
                    variant="danger"
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>
          )}
          <div className="overflow-auto">
            <Table
              style={{
                boxShadow: "2px 3px 15px",
                textAlign: "left",
                border: "1px solid gray",
              }}
              striped
              bordered
              hover
            >
              <thead>
                <tr>
                  <th>Sr.No</th>
                  <th>Complainer Mobile Number</th>
                  <th>Social Media Form</th>
                  <th>Fraud 1930</th>
                  <th>Ransomware</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((element, index) => (
                  <>
                    <tr>
                      <td>{(pageId - 1) * pageCount + (index + 1)}</td>
                      <td>{element?.obj.mobile_number}</td>
                      <td>
                        {element?.obj.social_media_fraud}
                        {parseInt(element?.obj.social_media_fraud) ? (
                          <span className="ms-2">
                            <TableDetailsEyeFunctionality
                              isDetails={element.SocialMediaIsDetails}
                              detailTitle="SocialMediaIsDetails"
                              isDetailsArr="SocialMediaArr"
                              path="social_media_fraud/get"
                              number={element.obj.mobile_number}
                              handleEyeOn={handleEyeOn}
                              handleEyeOff={handleEyeOff}
                              index={index}
                            />
                          </span>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        {element?.obj.fraud_1930}
                        {parseInt(element?.obj.fraud_1930) ? (
                          <span className="ms-2">
                            <TableDetailsEyeFunctionality
                              isDetails={element.Fraud1930IsDetails}
                              handleEyeOn={handleEyeOn}
                              isDetailsArr="Fraud1930Arr"
                              path="1930/get"
                              number={element.obj.mobile_number}
                              detailTitle="Fraud1930IsDetails"
                              handleEyeOff={handleEyeOff}
                              index={index}
                            />{" "}
                          </span>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        {element?.obj.ransomware}
                        {parseInt(element?.obj.ransomware) ? (
                          <span className="ms-2">
                            <TableDetailsEyeFunctionality
                              isDetails={element.RansomwareIsDetails}
                              isDetailsArr="RansomwareArr"
                              path="ransomware/get"
                              number={element.obj.mobile_number}
                              handleEyeOn={handleEyeOn}
                              detailTitle="RansomwareIsDetails"
                              handleEyeOff={handleEyeOff}
                              index={index}
                            />
                          </span>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>{element?.obj.Total}</td>
                    </tr>
                    {element.SocialMediaIsDetails && (
                      <tr>
                        <td colSpan={10}>
                          <Table
                            style={{
                              textAlign: "left",
                              border: "1px solid gray",
                            }}
                            striped
                            bordered
                            hover
                          >
                            <thead>
                              <tr>
                                <th>Complainer Name</th>
                                <th>Complainer Mobile Number</th>
                                <th>Fraud At</th>
                                <th>Remarks</th>
                              </tr>
                            </thead>
                            <tbody>
                              {element.SocialMediaArr.map((element,index)=>(
                                <tr key={index}>
                                        <td style={{ width: "20%" }}>
                                          {element?.username}
                                        </td>
                                        <td>{element.mobile_number}</td>
                                        <td>{element?.fraud_at
                                            ? moment(
                                                element?.fraud_at
                                              ).format("DD-MM-YYYY")
                                            : ""}</td>
                                        <td>{element.remarks}</td>
                                      </tr>
                              ))}
                                      
                                    </tbody>
                          </Table>
                        </td>
                      </tr>
                    )}
                    {element.Fraud1930IsDetails && (
                      <tr>
                        <td colSpan={10}>
                          <Table
                            style={{
                              textAlign: "left",
                              border: "1px solid gray",
                            }}
                            striped
                            bordered
                            hover
                          >
                            <thead>
                              <tr>
                                <th>Complainer Mobile Number	</th>
                                <th>Register ID	</th>
                                <th>Police Station Name	</th>
                                <th>Remarks</th>
                                <th>Fraud At</th>
                              </tr>
                            </thead>
                            <tbody>
                              {element.Fraud1930Arr.map((element,index)=>(
                                <tr>

                                        <td key={index}>{element.mobile_number}</td>
                                        <td>{element.register_id}</td>
                                        <td>{((element.police_station_details && element.police_station_details.length) ? 
                                        element.police_station_details[0]?.police_station  : "" )}</td>
                                        <td>{element.remarks}</td>
                                        <td>{element?.fraud_at
                                            ? moment(
                                                element?.fraud_at
                                              ).format("DD-MM-YYYY")
                                            : ""}</td>
                                      </tr>
                              ))}
                                      
                                    </tbody>
                          </Table>
                        </td>
                      </tr>
                    )}
                    {element.RansomwareIsDetails && (
                      <tr>
                        <td colSpan={10}>
                          <Table
                            style={{
                              textAlign: "left",
                              border: "1px solid gray",
                            }}
                            striped
                            bordered
                            hover
                          >
                            <thead>
                              <tr>
                                <th>Suspect Mobile Number	</th>
                                <th>Ransomware Type	</th>
                                <th>Receiver Mobile Number	</th>
                                <th>Police Station Name	</th>
                                <th>Fraud At</th>
                                <th>Remarks</th>

                              </tr>
                            </thead>
                            <tbody>
                              {element.RansomwareArr.map((element,index)=>(
                                <tr key={index}>

                                        <td>{element.mobile_number}</td>
                                        <td>{element?.ransomware_type=="1" ? "Spam Call" : element?.ransomware_type=="2" ?"Video Call" : ""}</td>
                                        <td>{element?.mobile_number}</td>
                                        <td>{(element?.police_station_details && element?.police_station_details.length) ? 
                                         element.police_station_details[0]?.police_station  : ""}</td>
                                        <td>{element?.fraud_at
                                            ? moment(
                                                element?.fraud_at
                                              ).format("DD-MM-YYYY")
                                            : ""}</td>
                                            <td>{element.remarks}</td>
                                      </tr>
                              ))}
                                      
                                    </tbody>
                          </Table>
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
              <tbody>
                <tr>
                  {!tableData.length && (
                    <td colSpan={11}>
                      <div
                        style={{
                          textAlign: "center",
                          padding: "20px",
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "#ff0000",
                          backgroundColor: "#f0f0f0",
                          border: "1px solid #e0e0e0",
                          borderRadius: "5px",
                          width: "100%",
                        }}
                      >
                        NO DATA FOUND
                      </div>
                    </td>
                  )}
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
        <div className="pt-2 pb-2  ">
          <Button variant="primary" onClick={handlePreviousPage} size="sm">
            PREVIOUS
          </Button>{" "}
          <Button variant="secondary" onClick={handleNextPage} size="sm">
            NEXT
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MostFraudReports;

const TableDetailsEyeFunctionality = ({
  isDetails,
  handleEyeOn,
  handleEyeOff,
  isDetailsArr,
path,
number,
  detailTitle,
  index,
}) => {
  return (
    <span className="ms-2">
      {" "}
      {!isDetails && (
        <img
          onClick={() => handleEyeOn(index, detailTitle,isDetailsArr,path,number)}
          src={EyeOpen}
          height={"18px"}
          alt=""
          className="cursor-pointer"
        />
      )}{" "}
      {isDetails && (
        <img
          className="cursor-pointer"
          onClick={() => handleEyeOff(index, detailTitle)}
          src={EyeClose}
          height={"18px"}
          alt=""
        />
      )}
    </span>
  );
};
