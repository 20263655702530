import React, { useEffect, useState } from "react";
import { radialBar } from "react-chartjs-2";
import { CYBER_CELL_URL, IMAGEMODELURL } from "../Process";
import ReactApexChart from "react-apexcharts";
import {
  Badge,
  Button,
  Col,
  Form,
  ListGroup,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
import { MdPending } from "react-icons/md";
import { GrInProgress } from "react-icons/gr";
import { SiProgress } from "react-icons/si";
import { FaLockOpen } from "react-icons/fa6";
import moment from "moment";
const Dashboard = () => {
  const [data, setData] = useState([]);
  const [overallData, setOverallData] = useState([])
  useEffect(() => {
    const today = moment();

    fetchInitialUserDetailsData(filterData?.StartData, filterData?.EndDate, "Main");
    fetchInitialUserDetailsData("2024-01-01", today.format('YYYY-MM-DD'), "OverAll");

  }, []);
  const currentTime = new Date();
  let formattedDate = currentTime.toISOString().slice(0, 16);
  const [maxDate, setMaxData] = useState(formattedDate);
  const UserImageDIR = `${IMAGEMODELURL}/User`;
  const [validDate, setValidDate] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [filterData, setFilterdata] = useState({
    StartData: new Date().toISOString().split("T")[0],
    EndDate: new Date().toISOString().split("T")[0],
  });
  const fetchInitialUserDetailsData = async (startDate, endDate, type) => {
    console.log(startDate, endDate, type, "ppppp")
    if (!validDate) {
      return;
    }
    console.log(
      filterData?.StartData,
      filterData?.EndDate,
      "jshfjasjd"
    );
    try {
      const res = await fetch(`${CYBER_CELL_URL}/dashboard/dashboard`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          start_date: startDate?.split("-").reverse().join("-"),
          end_date: endDate?.split("-").reverse().join("-"),
        }),
      });
      const result = await res.json();
      console.log(result, "result");
      if (type == "Main") {
        setData(result.data)
      } else if (type == "OverAll") {
        setOverallData(result.data)
      }
    } catch (err) {
      console.log(err);
    }
  };

  const placeChart = {
    series: [data?.total_data?.total_link || 0],
    options: {
      chart: {
        height: 350,
        type: "radialBar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: "70%",
            background: "#fff",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: "#fff",
            strokeWidth: "67%",
            margin: 0,
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },
          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: "#888",
              fontSize: "17px",
            },
            value: {
              formatter: function (val) {
                return parseInt(val);
              },
              color: "#111",
              fontSize: "36px",
              show: true,
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#ABE5A1"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: "round",
      },
      labels: ["Total"],
    },
  };
  const OverAllTChart = {
    series: [overallData?.total_data?.total_link || 0],
    options: {
      chart: {
        height: 350,
        type: "radialBar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: "70%",
            background: "#fff",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: "#fff",
            strokeWidth: "67%",
            margin: 0,
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },
          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: "#888",
              fontSize: "17px",
            },
            value: {
              formatter: function (val) {
                return parseInt(val);
              },
              color: "#111",
              fontSize: "36px",
              show: true,
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#ABE5A1"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: "round",
      },
      labels: ["Total"],
    },
  };
  const socialMediaChart = {
    series: [data?.total_data?.social_media_fraud || 0],
    options: {
      chart: {
        height: 350,
        type: "radialBar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: "70%",
            background: "#fff",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: "#fff",
            strokeWidth: "67%",
            margin: 0,
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },
          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: "#888",
              fontSize: "17px",
            },
            value: {
              formatter: function (val) {
                return parseInt(val);
              },
              color: "#111",
              fontSize: "36px",
              show: true,
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#ABE5A1"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: "round",
      },
      labels: ["Social Media"],
    },
  };
  const overAllSocialMediaChart = {
    series: [overallData?.total_data?.social_media_fraud || 0],
    options: {
      chart: {
        height: 350,
        type: "radialBar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: "70%",
            background: "#fff",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: "#fff",
            strokeWidth: "67%",
            margin: 0,
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },
          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: "#888",
              fontSize: "17px",
            },
            value: {
              formatter: function (val) {
                return parseInt(val);
              },
              color: "#111",
              fontSize: "36px",
              show: true,
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#ABE5A1"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: "round",
      },
      labels: ["Social Media"],
    },
  };
  const chart1930 = {
    series: [data?.total_data?.fraud_1930 || 0],
    options: {
      chart: {
        height: 350,
        type: "radialBar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: "70%",
            background: "#fff",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: "#fff",
            strokeWidth: "67%",
            margin: 0,
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },
          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: "#888",
              fontSize: "17px",
            },
            value: {
              formatter: function (val) {
                return parseInt(val);
              },
              color: "#111",
              fontSize: "36px",
              show: true,
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#ABE5A1"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: "1930",
      },
      labels: ["1930"],
    },
  };
  const OverAllChart1930 = {
    series: [overallData?.total_data?.fraud_1930 || 0],
    options: {
      chart: {
        height: 350,
        type: "radialBar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: "70%",
            background: "#fff",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: "#fff",
            strokeWidth: "67%",
            margin: 0,
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },
          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: "#888",
              fontSize: "17px",
            },
            value: {
              formatter: function (val) {
                return parseInt(val);
              },
              color: "#111",
              fontSize: "36px",
              show: true,
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#ABE5A1"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: "1930",
      },
      labels: ["1930"],
    },
  };
  const spamChart = {
    series: [data?.total_data?.ransomware || 0],
    options: {
      chart: {
        height: 350,
        type: "radialBar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: "70%",
            background: "#fff",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: "#fff",
            strokeWidth: "67%",
            margin: 0,
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },
          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: "#888",
              fontSize: "17px",
            },
            value: {
              formatter: function (val) {
                return parseInt(val);
              },
              color: "#111",
              fontSize: "36px",
              show: true,
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#ABE5A1"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: "round",
      },
      labels: ["Spam Call"],
    },
  };
  const OverAllSpamChart = {
    series: [overallData?.total_data?.ransomware || 0],
    options: {
      chart: {
        height: 350,
        type: "radialBar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: "70%",
            background: "#fff",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: "#fff",
            strokeWidth: "67%",
            margin: 0,
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },
          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: "#888",
              fontSize: "17px",
            },
            value: {
              formatter: function (val) {
                return parseInt(val);
              },
              color: "#111",
              fontSize: "36px",
              show: true,
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#ABE5A1"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: "round",
      },
      labels: ["Spam Call"],
    },
  };
  const handleInputChange = (e) => {
    if (e.target.name == "StartData") {
      if (e.target.value > filterData.EndDate) {
        setValidDate(false);
      } else {
        setValidDate(true);
      }
    } else if (e.target.name == "EndDate") {
      if (filterData.StartData > e.target.value) {
        setValidDate(false);
      } else {
        setValidDate(true);
      }
    }

    console.log(
      e.target.value.split("-").reverse().join("-"),
      "e.target.value"
    );
    setFilterdata({
      ...filterData,
      [e.target.name]: e.target.value,
    });
  };
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const handleFilterCancle = () => {
    let tempt = {
      StartData: new Date().toISOString().split("T")[0],
      EndDate: new Date().toISOString().split("T")[0],
    };
    setFilterdata(tempt);
    setValidDate(true)
    fetchInitialUserDetailsData(tempt?.StartData, tempt?.EndDate, "Main");
  };
  return (
    <div
      className="body-wrapper"
      style={{
        backgroundColor: "#d3d3d3",
        minHeight: "100vh",
        minWidth: "100vh",
      }}
    >
      <div className="container-fluid">
        <Row
          style={{
            width: "100%",
            margin: "auto",
            marginTop: "0.4em",
            marginBottom: "0.3em",
          }}
        >
          <Col>
            <Button
              className="float-end"
              md={2}
              variant="light"
              style={{ border: "1px solid gray" }}
              size="sm"
              onClick={handleToggle}
            >
              {isOpen ? "Close Filter" : "Open Filter"}
            </Button>
          </Col>
        </Row>
        {isOpen && (
          <div
            className="filter-content"
            style={{
              boxShadow: "2px 3px 15px",
              marginTop: "20px",
              padding: "20px",
              backgroundColor: "white",
              borderRadius: "5px",
              width: "100%",
              textAlign: "center",
              margin: "auto",
            }}
          >
            <Form className="d-flex gap-4 ">
              <div className="form-group">
                <label htmlFor="fraud_at" className="form-label fw-semibold">
                  Start Date
                  {/*  */}
                </label>
                <input
                  onKeyDown={(e) => e.preventDefault()}
                  onChange={handleInputChange}
                  name="StartData"
                  max={new Date().toISOString().split("T")[0]}
                  value={filterData.StartData}
                  type="date"
                  className="form-control"
                  placeholder="Fraud At"
                // style={{border:`1px solid ${(isSubmit && !formData.sender_bank_name.textValue) ? "red" : isSubmit ? "green" : "light-gray" }  `}}
                ></input>
                {/* {isSubmit && !formData.sender_bank_name.textValue && (
                        <span className="text-danger">*</span>
                      )} */}
              </div>
              <div className="form-group">
                <label htmlFor="fraud_at" className="form-label fw-semibold">
                  End Date
                  {/*  */}
                </label>
                <input
                  onKeyDown={(e) => e.preventDefault()}
                  onChange={handleInputChange}
                  name="EndDate"
                  max={new Date().toISOString().split("T")[0]}
                  value={filterData.EndDate}
                  type="date"
                  className="form-control"
                  placeholder="Fraud At"
                // style={{border:`1px solid ${(isSubmit && !formData.sender_bank_name.textValue) ? "red" : isSubmit ? "green" : "light-gray" }  `}}
                ></input>
                {!validDate && (
                  <span className="text-danger">
                    End Date must be Greater than start Date
                  </span>
                )}
              </div>
              <div style={{ position: "relative", top: "30px" }}>
                <Button
                  variant="primary"
                  className="me-2  "
                  onClick={() => {
                    // searchHandle(pageCount, 0,filterData)
                    fetchInitialUserDetailsData(filterData?.StartData, filterData?.EndDate, "Main");
                  }}
                >
                  Apply
                </Button>
                {/* <Button onClick={()=>{fetchExcelData()}
                    }   className="me-2" variant="success">
                    Export
                  </Button> */}
                <Button onClick={handleFilterCancle} variant="danger">
                  Cancel
                </Button>
              </div>
            </Form>
          </div>
        )}
        <div className="col-md-12 mt-4">
          <div
            className="row"
          // style={{
          //   display: "flex",
          //   flexWrap: "wrap",
          //   justifyContent: "space-evenly",
          // }}
          >
            {/* First Box */}
            {/* <div style={{boxShadow: "2px 6px 15px",width:"23%",marginBottom:"3rem" }}> */}

            <div className="col-md-3">
              <div
                style={{
                  // width: "17rem",
                  minHeight: "18rem",
                  border: "2px solid white",
                }}
                className="card bg-dark text-white rounded h-100"
              >
                <h5
                  className="card-title"
                  style={{
                    fontSize: "1.5em",
                    position: "relative",
                    textAlign: "center",
                    top: "1.2em",
                    fontWeight: "650",
                    color: "white",
                  }}
                >
                  Overall
                </h5>

                <div className="card-body" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                  <div id="chart">
                    <ReactApexChart
                      options={OverAllTChart?.options}
                      series={OverAllTChart?.series}
                      type="radialBar"
                      height={250}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div
                style={{
                  // width: "17rem",
                  border: "2px solid white",
                  minHeight: "18rem",
                }}
                className="card  bg-dark  text-white rounded h-100"
              >
                <h5
                  className="card-title"
                  style={{
                    fontSize: "1.5em",
                    position: "relative",
                    textAlign: "center",
                    top: "1.2em",
                    fontWeight: "650",
                    color: "white",
                  }}
                >
                  Overall Social Media
                </h5>
                <div className="card-body" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                  <div id="chart">
                    <ReactApexChart
                      options={overAllSocialMediaChart?.options}
                      series={overAllSocialMediaChart?.series}
                      type="radialBar"
                      height={250}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div
                style={{
                  // width: "17rem",
                  border: "2px solid white",
                  minHeight: "18rem",
                }}
                className="card  bg-dark text-white rounded h-100"
              >
                <h5
                  className="card-title"
                  style={{
                    fontSize: "1.5em",
                    position: "relative",
                    textAlign: "center",
                    top: "1.2em",
                    fontWeight: "650",
                    color: "white",
                  }}
                >
                  Overall 1930
                </h5>
                <div className="card-body" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                  <div id="chart">
                    <ReactApexChart
                      options={OverAllChart1930?.options}
                      series={OverAllChart1930?.series}
                      type="radialBar"
                      height={250}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div
                style={{
                  // width: "17rem",
                  border: "2px solid white",
                  minHeight: "15rem",
                }}
                className="card  bg-dark text-white rounded h-100"
              >
                <h5
                  className="card-title"
                  style={{
                    fontSize: "1.5em",
                    position: "relative",
                    textAlign: "center",
                    top: "1.2em",
                    fontWeight: "650",
                    color: "white",
                  }}
                >
                  Overall Spam Call
                </h5>
                <div className="card-body" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                  <div id="chart">
                    <ReactApexChart
                      options={OverAllSpamChart?.options}
                      series={OverAllSpamChart?.series}
                      type="radialBar"
                      height={250}
                    />
                  </div>
                </div>
              </div>
            </div>

          </div>


          <div
            className="row"
            style={{
              // display: "flex",
              // flexWrap: "wrap",
              // justifyContent: "space-evenly",
              marginTop: "15px",
              marginBottom: "15px"
            }}
          >
            {/* First Box */}
            {/* <div style={{boxShadow: "2px 6px 15px",width:"23%",marginBottom:"3rem" }}> */}

            <div className="col-md-3">
              <div
                style={{
                  // width: "17rem",
                  minHeight: "27.4rem",
                  border: "2px solid white",
                }}
                className="card   bg-primary text-white rounded h-100"
              >
                <h5
                  className="card-title"
                  style={{
                    fontSize: "1.5em",
                    position: "relative",
                    textAlign: "center",
                    top: "1.2em",
                    fontWeight: "650",
                    color: "white",
                  }}
                >
                  Total link
                </h5>

                <div className="card-body">
                  <div id="chart">
                    <ReactApexChart
                      options={placeChart?.options}
                      series={placeChart?.series}
                      type="radialBar"
                      height={250}
                    />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className="dashboardIcon">
                      <FaLockOpen
                        style={{ marginBottom: "3px", marginRight: "3px" }}
                      />
                      Open Link : {data?.total_data?.total_open_link}{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div
                style={{
                  // width: "17rem",
                  border: "2px solid white",
                  minHeight: "18rem",
                }}
                className="card  bg-success  text-white rounded h-100"
              >
                <h5
                  className="card-title"
                  style={{
                    fontSize: "1.5em",
                    position: "relative",
                    textAlign: "center",
                    top: "1.2em",
                    fontWeight: "650",
                    color: "white",
                  }}
                >
                  Total Social Media
                </h5>
                <div className="card-body">
                  <div id="chart">
                    <ReactApexChart
                      options={socialMediaChart?.options}
                      series={socialMediaChart?.series}
                      type="radialBar"
                      height={250}
                    />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className="dashboardIcon">
                      <MdPending
                        style={{ marginBottom: "3px", marginRight: "3px" }}
                      />
                      Pending : {data?.total_data?.social_media_fraud_pending}{" "}
                    </div>
                    <div className="dashboardIcon">
                      <IoCheckmarkDoneCircle
                        style={{ marginBottom: "3px", marginRight: "3px" }}
                      />
                      Complete : {data?.total_data?.social_media_fraud_completed}
                    </div>
                    <div className="dashboardIcon">
                      <SiProgress
                        style={{ marginBottom: "3px", marginRight: "3px" }}
                      />
                      In Progress :{" "}
                      {data?.total_data?.social_media_fraud_in_progress}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div
                style={{
                  // width: "17rem",
                  border: "2px solid white",
                  minHeight: "18rem",
                }}
                className="card  bg-warning text-white rounded h-100"
              >
                <h5
                  className="card-title"
                  style={{
                    fontSize: "1.5em",
                    position: "relative",
                    textAlign: "center",
                    top: "1.2em",
                    fontWeight: "650",
                    color: "white",
                  }}
                >
                  Total 1930
                </h5>
                <div className="card-body">
                  <div id="chart">
                    <ReactApexChart
                      options={chart1930?.options}
                      series={chart1930?.series}
                      type="radialBar"
                      height={250}
                    />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className="dashboardIcon">
                      <MdPending
                        style={{ marginBottom: "3px", marginRight: "3px" }}
                      />
                      Pending : {data?.total_data?.fraud_1930_pending}{" "}
                    </div>
                    <div className="dashboardIcon">
                      <IoCheckmarkDoneCircle
                        style={{ marginBottom: "3px", marginRight: "3px" }}
                      />
                      Complete : {data?.total_data?.fraud_1930_completed}
                    </div>
                    <div className="dashboardIcon">
                      <SiProgress
                        style={{ marginBottom: "3px", marginRight: "3px" }}
                      />
                      In Progress : {data?.total_data?.fraud_1930_in_progress}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div
                style={{
                  // width: "17rem",
                  border: "2px solid white",
                  minHeight: "15rem",
                }}
                className="card  bg-danger text-white rounded h-100"
              >
                <h5
                  className="card-title"
                  style={{
                    fontSize: "1.5em",
                    position: "relative",
                    textAlign: "center",
                    top: "1.2em",
                    fontWeight: "650",
                    color: "white",
                  }}
                >
                  Total Spam Call
                </h5>
                <div className="card-body">
                  <div id="chart">
                    <ReactApexChart
                      options={spamChart?.options}
                      series={spamChart?.series}
                      type="radialBar"
                      height={250}
                    />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className="dashboardIcon">
                      <MdPending
                        style={{ marginBottom: "3px", marginRight: "3px" }}
                      />
                      Pending : {data?.total_data?.ransomware_pending}{" "}
                    </div>
                    <div className="dashboardIcon">
                      <IoCheckmarkDoneCircle
                        style={{ marginBottom: "3px", marginRight: "3px" }}
                      />
                      Complete : {data?.total_data?.ransomware_completed}
                    </div>
                    <div className="dashboardIcon">
                      <SiProgress
                        style={{ marginBottom: "3px", marginRight: "3px" }}
                      />
                      In Progress : {data?.total_data?.ransomware_in_progress}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="row">
            <div className="row">
              <div className="col-md-12">
                <div
                  style={{ boxShadow: "2px 6px 15px" }}
                  className="card p-4 rounded"
                >
                  <h5 className="card-title ms-3">Other Details </h5>
                  <Tabs
                    defaultActiveKey="home"
                    id="fill-tab-example"
                    className="mb-3"
                    fill
                  >
                    <Tab eventKey="home" title="Social Media Form">
                      <div
                        className="px-2 mb-4 ms-1  "
                        style={{ borderBottom: "1px solid gray" }}
                      >
                        <h5> Fraud Type Details</h5>
                      </div>
                      <ListGroup
                        style={{ display: "flex", flexWrap: "wrap" }}
                        horizontal
                        numbered
                      >
                        {data?.fraud_type?.map((element, index) => (
                          <ListGroup.Item
                            style={{
                              backgroundColor: "gray",
                              color: "white",
                              margin: "0.3em",
                              borderRadius: "5px",
                            }}
                            className="d-flex justify-content-between align-items-start"
                          >

                            <div className="ms-2 me-2">
                              <div className="fw-bold">
                                {element?.fraud_type}
                              </div>
                            </div>
                            <Badge bg="primary" pill>
                              {(element?.complete || 0) +
                                (element?.pending || 0) +
                                (element?.in_progress || 0)}
                            </Badge>
                          </ListGroup.Item>
                        ))}
                        {!data?.fraud_type?.length && <div
                          style={{
                            textAlign: "center",
                            padding: "20px",
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "#ff0000",
                            backgroundColor: "rgb(240, 240, 240)",
                            border: "1px solid #e0e0e0",
                            borderRadius: "5px",
                            width: "100%",
                          }}
                        >
                          NO DATA FOUND
                        </div>}

                      </ListGroup>
                      <div
                        className="px-2 mb-4 mt-4 ms-1"
                        style={{ borderBottom: "1px solid gray" }}
                      >
                        <h5> Police Station Type Details</h5>
                      </div>
                      <ListGroup
                        style={{ display: "flex", flexWrap: "wrap" }}
                        horizontal
                        numbered
                      >
                        {data?.police_station?.map((element, index) => (
                          <ListGroup.Item
                            style={{
                              backgroundColor: "gray",
                              color: "white",
                              margin: "0.3em",
                              borderRadius: "5px",
                            }}
                            className="d-flex justify-content-between align-items-start"
                          >
                            <div className="ms-2 me-2">
                              <div className="fw-bold">
                                {element?.police_station}
                              </div>
                            </div>
                            <Badge bg="primary" pill>
                              {(element?.complete || 0) +
                                (element?.pending || 0) +
                                (element?.in_progress || 0)}
                            </Badge>
                          </ListGroup.Item>
                        ))}
                        {!data?.police_station?.length && <div
                          style={{
                            textAlign: "center",
                            padding: "20px",
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "#ff0000",
                            backgroundColor: "rgb(240, 240, 240)",
                            border: "1px solid #e0e0e0",
                            borderRadius: "5px",
                            width: "100%",
                          }}
                        >
                          NO DATA FOUND
                        </div>}
                      </ListGroup>
                    </Tab>
                    <Tab eventKey="profile" title="1930 Form">
                      <div
                        style={{
                          textAlign: "center",
                          padding: "20px",
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "#ff0000",
                          backgroundColor: "rgb(240, 240, 240)",
                          border: "1px solid #e0e0e0",
                          borderRadius: "5px",
                          width: "100%",
                        }}
                      >
                        NO DATA FOUND
                      </div>
                    </Tab>
                    <Tab eventKey="longer-tab" title="Spam Call Fraud">
                      <div
                        className="px-2 mb-4 ms-1  "
                        style={{ borderBottom: "1px solid gray" }}
                      >
                        <h5> Fraud Type Details</h5>
                      </div>
                      <ListGroup
                        style={{ display: "flex", flexWrap: "wrap" }}
                        horizontal
                        numbered
                      >
                        {data?.ransomware_type?.map((element, index) => (
                          <ListGroup.Item
                            style={{
                              backgroundColor: "gray",
                              color: "white",
                              margin: "0.3em",
                              borderRadius: "5px",
                            }}
                            className="d-flex justify-content-between align-items-start"
                          >
                            <div className="ms-2 me-2">
                              <div className="fw-bold">
                                {element?.ransomware_type}
                              </div>
                            </div>
                            <Badge bg="primary" pill>
                              {(element?.complete || 0) +
                                (element?.pending || 0) +
                                (element?.in_progress || 0)}
                            </Badge>
                          </ListGroup.Item>
                        ))}
                        {!data?.ransomware_type?.length && <div
                          style={{
                            textAlign: "center",
                            padding: "20px",
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "#ff0000",
                            backgroundColor: "rgb(240, 240, 240)",
                            border: "1px solid #e0e0e0",
                            borderRadius: "5px",
                            width: "100%",
                          }}
                        >
                          NO DATA FOUND
                        </div>}
                      </ListGroup>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
