import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { CYBER_CELL_URL } from "../Process";
import Swal from "sweetalert2";
import { handleLogout } from "./CommonComponents";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

const ChangeStatusModal = ({
  apiRoute,
  openModel,
  setOpenModel,
  id,
  currentObj,
  filterData,
  fetchInitialPlaceDetailsData,
  pageCount
}) => {
  console.log(currentObj.status,"currentObj.status")
  const navigate = useNavigate();
  const [newStatus, setNewStatus] = useState(currentObj.status ); // 'complete', 'in progress', 'pending'
  const [remark, setRemark] = useState(currentObj.status==1 ? currentObj.in_progress_remark :currentObj.status==2 ? currentObj.complete_remark : currentObj.status==0 ? "" : "" );
  const handleChangeStatus = async () => {
    const messageres=await Swal.fire({
      title: "Do you want to save the changes?",
      showCancelButton: true,
      buttonsStyling: false,
      confirmButtonText: "Save",
      customClass: {
        confirmButton: "btn btn-primary me-3", // React-Bootstrap primary button style
        cancelButton: "btn btn-danger ", // React-Bootstrap secondary button style
      },
    })
    
      /* Read more about isConfirmed, isDenied below */
      if (messageres?.isDismissed) {
        Swal.fire("Changes are not saved");
        return
      }
    try {
      let jsonObj={
        status: newStatus,
        id: id,
      }
      if(newStatus==1){
        jsonObj.in_progress_remark=remark

      }else if(newStatus==2){
        jsonObj.complete_remark=remark
      }
      const res = await fetch(
        `${CYBER_CELL_URL}/${apiRoute}/update`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
           ...jsonObj
          }),
        }
      );
      const result = await res.json();
      // setTotalCount(result.total[0].TotalCount)
      if(result.status==true){
        Swal.fire({
          title: "Status Updated successfully!",
          icon: "success",
          timer: 1500
        });
        
      }else{
        Swal.fire({
          title: "Could Not Update Status!",
          icon: "error",
          timer: 1500
        });
      }

   setOpenModel(false)
   fetchInitialPlaceDetailsData(pageCount,filterData)
    } catch (err) {

      console.log(err);
    }
  
  };

  return (
    <Modal show={openModel} centered>
      <Modal.Header>
        <Modal.Title>Change Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="newStatus">
            <Form.Label>Select Status:</Form.Label>
            <Form.Control
              as="select"
              value={newStatus}
              
              onChange={(e) => setNewStatus(e.target.value)}
            >
              <option value={2}>Complete</option>
              <option value={1}>In Progress</option>
              <option value={0}>Pending</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="remark">
            <Form.Label>Remark:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Add a remark (optional)"
              value={remark}
              onChange={(e) => setRemark(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setOpenModel(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={handleChangeStatus}>
          Change Status
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeStatusModal;
