import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { IMAGEMODELURL, CYBER_CELL_URL } from '../../Process'
import { useLocation, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";
import { FaArrowCircleLeft, FaArrowLeft } from "react-icons/fa";
import axios from "axios";
import moment from "moment";
import UserValidationPage from "./ValidateUser";
import { useCookies } from "react-cookie";
import ValidateModel from "./ValidateModel";
const SocialMediaFraud = () => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const FileRef = useRef(null);
  const navigate = useNavigate();
  const currentTime = new Date();
  let formattedDate = currentTime.toISOString().slice(0, 16);
  const [maxDate, setMaxData] = useState(formattedDate);
  const [validmail, setValidMail] = useState(true);
  const [validUserMail, setValidUserMain] = useState(true);
  const location = useLocation();
  const [modalShow, setModalShow] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const currentUrl = IMAGEMODELURL + location.pathname;
  const [validateExpiry, setValidateExpiry] = useState(true);
  const [fraudType, setFraudType] = useState([]);
  const [policeType, SetPoliceType] = useState([]);
  
  const [mediaType, setMediaType] = useState([]);
  const [formData, setFormData] = useState({
    id: {
      textValue: "",
      required: false,
    },
    gender: {
      textValue: "",
      required: false,
    },
    birth_date: {
      textValue: "",
      required: false,
    },
    social_media_fraud_type: {
      textValue: "",
      required: true,
    },
    mail_address: {
      textValue: "",
      required: false,
    },
    police_station_sr_no: {
      textValue: "",
      required: false,
    },
    media_platform_type_id: {
      textValue: "",
      required: true,
    },
    file_name: {
      textValue: "",
      required: false,
      isRemove: false,
      valid: true,
    },
    profile_url: {
      textValue: "",
      required: false,
      isRemove: false,
    },
    username: {
      textValue: "",
      required: true,
    },
    address: {
      textValue: "",
      required: true,
    },
    suspect_name: {
      textValue: "",
      required: true,
    },
    suspect_address: {
      textValue: "",
      required: false,
    },
    suspect_mobile_number: {
      textValue: "",
      required: true,
    },
    suspect_mail_address: {
      textValue: "",
      required: false,
    },
    suspect_profile_link: {
      textValue: "",
      required: false,
    },
    mobile_number: {
      textValue: "",
      required: false,
    },
    remarks: {
      textValue: "",
      required: false,
    },
    fraud_at: {
      textValue: "",
      required: false,
    },
  });
  useEffect(() => {
    handleGetFraudType();
    handleGetPoliceStationType();
    handleGetMediaType();
    handleValidateUrl();
    if (cookies.currentValidUser == 3) {

      setModalShow(false);
  

    } else {
      setModalShow(true);
    }
  }, []);
  const handleClearButtonClick = () => {
    FileRef.current.value = "";
  };

  const handleGetPoliceStationType = async () => {
    const res = await fetch(`${CYBER_CELL_URL}police_station/get`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({}),
    });
    const result = await res.json();

    SetPoliceType(result.data);
  };
  const handleGetMediaType = async () => {
    const res = await fetch(`${CYBER_CELL_URL}media_type/get`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({}),
    });
    const result = await res.json();

    setMediaType(result.data);
  };
  const handleGetFraudType = async () => {
    const res = await fetch(`${CYBER_CELL_URL}social_media_fraud_type/get`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({}),
    });
    const result = await res.json();
    
    setFraudType(result.data);
  };
  const handleValidateUrl = async () => {
    const res = await fetch(`${CYBER_CELL_URL}global_link/validate_link`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        link: currentUrl,
      }),
    });
    const result = await res.json();
    console.log(result, "result");
    if (result.status == false) {
      return navigate("/");
    }
    if (result?.data[0]?.link_expire_at < currentTime.toISOString()) {
      setValidateExpiry(false);
    } else {
      setValidateExpiry(true);
      handleUserData(result?.data[0]?.uid);
    }
  };
  const handleUserData = async (uuid) => {
    const res = await fetch(`${CYBER_CELL_URL}social_media_fraud/get`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        start_date: null,
        end_date: null,
        is_pagination: null,
        per_page: null,
        id: null,
        unique_token_id: uuid,
      }),
    });

    const result = await res.json();
    console.log(result, "finalResult");
    if (result?.data?.length) {
      let formattedDateString;
      if (result?.data[0]?.fraud_at) {
        const dateString = result?.data[0]?.fraud_at;
        const dateObject = new Date(dateString);
        formattedDateString = dateObject.toISOString().slice(0, 16);
      }
      setFormData({
        id: {
          textValue: result.data[0]?.id || "",
          required: false,
        },
        gender: {
          textValue: result.data[0]?.gender || "",
          required: false,
        },
        birth_date: {
          textValue:
          result.data[0]?.birth_date ? moment(result.data[0]?.birth_date).format("YYYY-MM-DD") : "",
          required: true,
        },
        mail_address: {
          textValue: result.data[0]?.mail_address || "",
          required: false,
        },
        police_station_sr_no: {
          textValue: result.data[0]?.police_station_sr_no || "",
          required: false,
        },
        media_platform_type_id: {
          textValue: result.data[0]?.media_platform_type_id || "",
          required: true,
        },
        file_name: {
          textValue: result.data[0]?.file_name || "",
          required: false,
          isRemove: false,
          valid: true,
        },
        profile_url: {
          textValue: result.data[0]?.gender || "",
          required: false,
          isRemove: false,
        },
        social_media_fraud_type: {
          textValue: result.data[0]?.social_media_fraud_type || "",
          required: true,
        },
        username: {
          textValue: result.data[0]?.username || "",
          required: true,
        },
        address: {
          textValue: result.data[0]?.address || "",
          required: true,
        },
        suspect_name: {
          textValue: result.data[0]?.suspect_name || "",
          required: true,
        },
        suspect_address: {
          textValue: result.data[0]?.suspect_address || "",
          required: false,
        },
        suspect_mobile_number: {
          textValue: result.data[0]?.suspect_mobile_number || "",
          required: false,
        },
        suspect_mail_address: {
          textValue: result.data[0]?.suspect_mail_address || "",
          required: false,
        },
        suspect_profile_link: {
          textValue: result.data[0]?.suspect_profile_link || "",
          required: true,
        },
        mobile_number: {
          textValue: result.data[0]?.mobile_number,
          required: false,
        },
        remarks: {
          textValue: result.data[0]?.remarks || "",
          required: false,
        },
        fraud_at: {
          textValue: formattedDateString || maxDate,
          required: false,
        },
      });
    }
  };
  const handleSubmit = (e) => {
    debugger
    setIsSubmit(true);
    if (!validmail || !validUserMail) {
      return;
    }
    for(let key in formData){
      if(formData[key].required==true){
        if(!formData[key].textValue){
          return
        }
      }
    }
    if(formData?.suspect_mobile_number?.textValue.length<10 && formData?.suspect_mobile_number?.textValue.length>0 ){
      return
    }
    if(formData?.media_platform_type_id?.textValue==0){
      return
    }
    if(formData?.social_media_fraud_type?.textValue==0){
      return
    }
    if(!formData?.suspect_profile_link?.textValue?.length || !formData?.suspect_profile_link?.textValue?.match('https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}') ){
      return 
    }
    console.log(formData, "formData");
    e.preventDefault();
  
    Swal.fire({
      title: "Do you want to save the changes?",
      showCancelButton: true,
      buttonsStyling: false,
      confirmButtonText: "Save",
      customClass: {
        confirmButton: "btn btn-primary me-3", // React-Bootstrap primary button style
        cancelButton: "btn btn-danger ", // React-Bootstrap secondary button style
      },
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        handleDataInsert();
        handleUpdateWithMedia();
      } else if (result?.isDenied) {
        Swal.fire("Changes are not saved");
      }
    });
  
  };
  const handleUpdateWithMedia = () => {
    console.log(formData.file_name, "formData2");
    const form = new FormData();
    form.append("id", formData?.id?.textValue);
    form.append("file_name", formData.file_name.textValue);
    if (formData.file_name.isRemove) {
      form.append("is_remove_all_documents", formData.file_name.isRemove);
    }
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    axios
      .post(`${CYBER_CELL_URL}/social_media_fraud/updateWithMedia`, form, config)
      .then((res) => {
        console.log(res, "res");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDataInsert = async () => {
    debugger;
    const dateObject = new Date(formData.fraud_at.textValue);
    console.log(formData, "formData");
    const res = await fetch(`${CYBER_CELL_URL}social_media_fraud/update`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: formData?.id?.textValue,
        is_submit: 1,
        social_media_fraud_type: formData.social_media_fraud_type.textValue,
        username: formData.username.textValue,
        address: formData.address.textValue,
        suspect_name: formData.suspect_name.textValue,
        suspect_address: formData.suspect_address.textValue,
        suspect_mobile_number: formData.suspect_mobile_number.textValue,
        suspect_mail_address: formData.suspect_mail_address.textValue,
        suspect_profile_link: formData.suspect_profile_link.textValue,
        remarks: formData.remarks.textValue,
        fraud_at:
          `${dateObject.getFullYear()}-${
            dateObject.getMonth() + 1
          }-${dateObject.getDate()} ${dateObject.getHours()}:${dateObject.getMinutes()}:${dateObject.getSeconds()}` ||
          maxDate,
        gender: formData.gender.textValue,
        birth_date: formData.birth_date.textValue,
        mail_address: formData.mail_address.textValue,
        police_station_sr_no: formData.police_station_sr_no.textValue,
        media_platform_type_id: formData.media_platform_type_id.textValue,
        profile_url: formData.profile_url.textValue,
      }),
    });
    const result = await res.json();
    if (result.status == true) {
      Swal.fire({
        title: "Saved! success",
        buttonsStyling: false,
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "btn btn-primary me-3", // React-Bootstrap primary button style
        },
      });
    } else {
      Swal.fire({
        title: `Changes are not saved , ${result.message}`,
        buttonsStyling: false,
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "btn btn-primary me-3", // React-Bootstrap primary button style
        },
      });
    }
  };
  const handleInputChange = (e) => {
    let lastInputString = e.target.value[e.target.value.length - 1];
    if (
      e.target.name == "suspect_mobile_number" ||
      e.target.name == "mobile_number"
    ) {
      if (
        e.target.value == "" ||
        (lastInputString.charCodeAt() >= 48 &&
          lastInputString.charCodeAt() <= 57)
      ) {
        setFormData({
          ...formData,
          [e.target.name]: {
            ...formData[e.target.name],
            textValue: e.target.value,
          },
        });
      } else {
        return;
      }
      return;
    }
    if (e.target.name === "suspect_mail_address") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(e.target.value)) {
        setValidMail(true);
      } else {
        setValidMail(false);
      }
    }
    if (e.target.name === "mail_address") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(e.target.value)) {
        setValidUserMain(true);
      } else {
        setValidUserMain(false);
      }
    }
    // let lastInputString = e.target.value[e.target.value.length - 1];

    setFormData({
      ...formData,
      [e.target.name]: {
        ...formData[e.target.name],
        textValue: e.target.value,
      },
    });
  };
  const handleCancle = () => {
    Swal.fire({
      title: "Do you want to Cancel the changes?",
      // showCancelButton: true,
      buttonsStyling: false,
      showDenyButton: true,
      denyButtonText: `No`,
      confirmButtonText: "Yes",
      confirmButtonColor: "#007AB1",
      denyButtonColor: "white",
      denyButtonBorder: "1px solid black",
      customClass: {
        confirmButton: "btn btn-primary me-3", // React-Bootstrap primary button style
        denyButton: "btn btn-danger ", // React-Bootstrap secondary button style
      },
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // handleDataInsert()
        setIsSubmit(false)
        setFormData((prev) => {
          return {
            id: {
              textValue: prev.id.textValue,
              required: false,
            },
            gender: {
              textValue: "",
              required: false,
            },
            birth_date: {
              textValue: "",
              required: true,
            },
            mail_address: {
              textValue: "",
              required: false,
            },
            police_station_sr_no: {
              textValue: "",
              required: false,
            },
            media_platform_type_id: {
              textValue: "",
              required: true,
            },
            file_name: {
              textValue: "",
              required: false,
              isRemove: true,
              valid: true,
            },
            profile_url: {
              textValue: "",
              required: false,
              isRemove: false,
            },
            social_media_fraud_type: {
              textValue: "",
              required: true,
            },
            username: {
              textValue: "",
              required: true,
            },
            address: {
              textValue: "",
              required: true,
            },
            suspect_name: {
              textValue: "",
              required: true,
            },
            suspect_address: {
              textValue: "",
              required: false,
            },
            suspect_mobile_number: {
              textValue: "",
              required: false,
            },
            suspect_mail_address: {
              textValue: "",
              required: false,
            },
            suspect_profile_link: {
              textValue: "",
              required: true,
            },
            mobile_number: {
              textValue: prev.mobile_number.textValue,
              required: false,
            },
            remarks: {
              textValue: "",
              required: false,
            },
            fraud_at: {
              textValue: maxDate,
              required: false,
            },
          };
        });
        handleClearButtonClick();
        setValidMail(true);
      }
    });
  };
  const handleFileChange = (e) => {
    if (
      ["jpg", "png", "jpeg", "pdf", "PDF"].includes(
        e.target.value.split(".")[e.target.value.split(".").length - 1]
      ) ||
      e.target.value === ""
    ) {
      setFormData({
        ...formData,
        [e.target.name]: {
          ...formData[e.target.name],
          textValue: e.target.files[0],
          valid: true,
          isRemove: true,
        },
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: {
          ...formData[e.target.name],
          textValue: e.target.files[0],
          valid: false,
        },
      });
    }
  };
  return (
    <div className="container mt-5 mb-5">
      <ValidateModel modalShow={modalShow} setModalShow={setModalShow} />
      {cookies.currentValidUser === 3 && <div className="row">
          <div style={{ textAlign: "center" }}>
            <h3 style={{ fontSize: "2.4em", fontWeight: "600" }}>
              CYBER CELL SURAT
            </h3>
            <h4 style={{ color: "gray", fontSize: "1.8em", fontWeight: "450" }}>
              Social Media Fraud
            </h4>
          </div>
          <div className="mb-3 me-2">
            <h5 style={{ fontSize: "2em", fontWeight: "400" }}>
              Registration Form
            </h5>
          </div>

          <div className="col-md-12">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="px-2 mb-4 ms-1  border-bottom">
                  <h5> Arjadar Details / અરજદાર વિગતો / अरजदार विवरण</h5>
                </div>
                <div className="col-md-3 ">
                  <div className="form-group mb-3">
                    <label
                      htmlFor="social_media_fraud_type"
                      className="form-label fw-semibold"
                    >
                      Fraud Type / છેતરપિંડીનો પ્રકાર / धोखाधड़ी का प्रकार<span style={{ color: "red" }}>*</span>
                    </label>
                    <Form.Select
                      onChange={handleInputChange}
                      name="social_media_fraud_type"
                      value={formData.social_media_fraud_type.textValue}
                      className="form-control"
                    >
                      <option value={""} selected="selected">
                        --Select Social Media Fraud Type --
                      </option>
                      {fraudType.map((element, index) => (
                        <option key={index} value={element.id}>
                          {element.fraud_type}
                        </option>
                      ))}
                    </Form.Select>
                   {isSubmit && formData.social_media_fraud_type.textValue==0 && <span style={{color:"red"}} >Please Select Fraud Type</span>}

                  </div>
                </div>
                <div className="col-md-3 mb-2">
                  <div className="form-group">
                    <label
                      htmlFor="mobile_number"
                      className="form-label fw-semibold"
                    >
                      {" "}
                      Arjadar Mobile Number /અરજદાર મોબાઈલ નંબર / अरजदार मोबाइल नंबर<span style={{ color: "red" }}></span>
                    </label>
                    <input
                      disabled={true}
                      onChange={handleInputChange}
                      name="mobile_number"
                      value={formData.mobile_number.textValue}
                      maxLength={10}
                      type="text"
                      className="form-control"
                      placeholder="Enter Mobile Number "
                      // style={{border:`1px solid ${(isSubmit && (!formData.contact.textValue || formData.contact.textValue.length!==10)) ? "red" : isSubmit ? "green" : "light-gray" }  `}}
                    ></input>
                    {/* {isSubmit && !formData.contact.textValue && (
                        <span className="text-danger">*</span>
                      )}
                      {isSubmit && formData.contact.textValue && formData.contact.textValue.length!==10 && <span className='text-danger'>Please Enter Valid Contact No</span>} */}
                  </div>
                </div>
                <div className="col-md-3 mb-3 ">
                  <div className="form-group">
                    <label
                      htmlFor="username"
                      className="form-label fw-semibold"
                    >
                     Arjadar Name / અરજદાર નામ /<br /> अरजदार नाम
<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      onChange={handleInputChange}
                      name="username"
                      value={formData.username.textValue}
                      type="text"
                      className="form-control"
                      placeholder="Name"
                    ></input>
                   {isSubmit && !formData.username.textValue.length && <span style={{color:"red"}} >Please Enter Arjadar Name</span>}
                  </div>
                </div>
                <div className="col-md-3 mb-3 "></div>
                <div className="col-md-3 mb-2">
                  <div className="form-group mb-3">
                    <label className="form-label fw-semibold">Arjadar Gender / અરજદાર જાતિ /<br /> अरजदार लिंग</label>
                    <div className="form-control">
                      <div className="d-flex align-items-center gap-2">
                        <label className="d-flex align-items-center gap-2">
                          <input
                            type="radio"
                            name="gender"
                            onChange={handleInputChange}
                            value={0}
                            checked={
                              formData?.gender?.textValue == 0 ? true : false
                            }
                          />
                          <span>Male</span>
                        </label>
                        <label className="d-flex align-items-center gap-2">
                          <input
                            type="radio"
                            name="gender"
                            value={1}
                            onChange={handleInputChange}
                            checked={
                              formData?.gender?.textValue == 1 ? true : false
                            }
                          />
                          <span>Female</span>
                        </label>
                        <label className="d-flex align-items-center gap-2">
                          <input
                            type="radio"
                            name="gender"
                            value={2}
                            onChange={handleInputChange}
                            checked={
                              formData?.gender?.textValue == 2 ? true : false
                            }
                          />
                          <span>Other</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="sender_branch_name"
                      className="form-label fw-semibold"
                    >
                    Arjadar  Mail Address / અરજદાર મેઈલ સરનામું /<br /> अरजादार मेल पता
                      {/*  */}
                    </label>
                    <input
                      onChange={handleInputChange}
                      name="mail_address"
                      value={formData?.mail_address?.textValue}
                      type="text"
                      className="form-control"
                      placeholder="Mail Address"
                    ></input>
                    {!validUserMail && (
                      <span style={{ color: "red" }}>Enter Valid MailID</span>
                    )}
                  </div>
                </div>
                <div className="col-md-3 mb-2">
                  <div className="form-group mb-3">
                    <label
                      htmlFor="birth_date"
                      className="form-label fw-semibold"
                    >
                    Arjadar  Date Of Birth / અરજદાર જન્મ તારીખ /<br /> अरजादार जन्म तिथि
                      {/*  */}
                    </label>
                    <input
                      onKeyDown={(e) => e.preventDefault()}
                      onChange={handleInputChange}
                      name="birth_date"
                      value={formData?.birth_date?.textValue}
                      type="date"
                      max={new Date().toISOString().split("T")[0]}
                      className="form-control"
                      placeholder="birth_date"
                    ></input>
                    {isSubmit && !formData.birth_date.textValue.length && <span style={{color:"red"}} >Please Enter Birth Date</span>}
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="profile_url"
                      className="form-label fw-semibold"
                    >
                      {" "}
                      Arjadar Profile Url / અરજદાર પ્રોફાઇલ યુઆરએલ / अरजादार प्रोफ़ाइल यूआरएल
                    </label>
                    <input
                      value={formData?.profile_url?.textValue}
                      onChange={handleInputChange}
                      name="profile_url"
                      type="text"
                      className="form-control"
                      placeholder="Enter Profile Link"
                    ></input>
                    {/* {hasSubmit && formData.videoLink.textValue && !validLink && <span className='text-danger'>Please Enter Valid Url</span>} */}
                  </div>
                </div>
                <div className="col-md-6 mb-4 ">
                  <div className="form-group">
                    <label className="form-label fw-semibold">Arjadar Address / અરજદાર સરનામું /<br /> अरजादार पता</label>
                    <textarea
                      onChange={handleInputChange}
                      name="address"
                      value={formData.address.textValue}
                      style={{ height: "125px" }}
                      rows="2"
                      cols="20"
                      placeholder="Enter Address"
                      className="form-control"
                    />
                        {isSubmit && !formData.address.textValue.length && <span style={{color:"red"}} >Please Enter Arjadar Address</span>}
                  </div>
                </div>
                <div className="col-md-3 mb-3 "></div>

                <div className="px-2 ms-1 mb-4  mt-2 border-bottom">
                  <h5> Suspect Details</h5>
                </div>
                <div className="col-md-3 mb-2 ">
                  <div className="form-group">
                    <label
                      htmlFor="suspect_name"
                      className="form-label fw-semibold"
                    >
                      Suspect Name / શંકાસ્પદ નામ /<br /> संदिग्ध का नाम <span style={{ color: "red" }}>*</span>
                      {/*  */}
                    </label>
                    <input
                      onChange={handleInputChange}
                      name="suspect_name"
                      value={formData.suspect_name.textValue}
                      type="text"
                      className="form-control"
                      placeholder="Suspect Name"
                      // style={{border:`1px solid ${(isSubmit && !formData.sender_bank_name.textValue) ? "red" : isSubmit ? "green" : "light-gray" }  `}}
                    ></input>
                      {isSubmit && (!formData.suspect_name.textValue )  && <span style={{color:"red"}}>Please Enter Suspect Name</span>}

                    {/* {isSubmit && !formData.sender_bank_name.textValue && (
                        <span className="text-danger">*</span>
                      )} */}
                  </div>
                  <div className="form-group mt-3">
                    <label
                      htmlFor="sender_ISFC_code"
                      className="form-label fw-semibold"
                    >
                      Suspect Profile Link  / શંકાસ્પદ પ્રોફાઇલ લિંક  /<br /> संदिग्ध प्रोफ़ाइल लिंक
                    </label>
                    <input
                      onChange={handleInputChange}
                      name="suspect_profile_link"
                      value={formData.suspect_profile_link.textValue}
                      type="text"
                      className="form-control"
                      placeholder="Suspect Profile Link"
                      // style={{border:`1px solid ${(isSubmit && !formData.sender_bank_name.textValue) ? "red" : isSubmit ? "green" : "light-gray" }  `}}
                    ></input>
                     {isSubmit && (!formData.suspect_profile_link.textValue.length || !formData.suspect_profile_link.textValue.match('https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}') ) && <span style={{color:"red"}} >Please Enter Suspect Profile Link</span>}
                    {/* {isSubmit && !formData.sender_bank_name.textValue && (
                        <span className="text-danger">*</span>
                      )} */}
                  </div>
                </div>
                <div className="col-md-3 mb-2">
                  <div className="form-group">
                    <label
                      htmlFor="mobile_number"
                      className="form-label fw-semibold"
                    >
                      {" "}
                      Suspect Mobile Number / શંકાસ્પદ મોબાઇલ નંબર / संदिग्ध मोबाइल नंबर<span style={{ color: "red" }}>*</span>
                      <span style={{ color: "red" }}></span>
                    </label>
                    <input
                      onChange={handleInputChange}
                      name="suspect_mobile_number"
                      value={formData.suspect_mobile_number.textValue}
                      maxLength={10}
                      type="text"
                      className="form-control"
                      placeholder="Suspect Mobile Number"
                    ></input>
                      {isSubmit && ( formData?.suspect_mobile_number?.textValue.length>0 && formData?.suspect_mobile_number?.textValue.length<10)  && <span style={{color:"red"}}>Please Enter Valid Suspect Mobile Number</span>}
                    {/* {isSubmit && !formData.contact.textValue && (
                        <span className="text-danger">*</span>
                      )}
                      {isSubmit && formData.contact.textValue && formData.contact.textValue.length!==10 && <span className='text-danger'>Please Enter Valid Contact No</span>} */}
                  </div>
                  <div className="form-group mt-3">
                    <label
                      htmlFor="sender_branch_name"
                      className="form-label fw-semibold"
                    >
                      Suspect Mail Address / શંકાસ્પદ મેઇલ સરનામું / <br /> संदिग्ध मेल पता
                      {/*  */}
                    </label>
                    <input
                      onChange={handleInputChange}
                      name="suspect_mail_address"
                      value={formData.suspect_mail_address.textValue}
                      type="text"
                      className="form-control"
                      placeholder="Suspect Mail Address"
                    ></input>
                    {!validmail && (
                      <span style={{ color: "red" }}>Enter Valid MailID</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6 mb-4 ">
                  <div className="form-group">
                    <label className="form-label fw-semibold">
                      Suspect Address / શંકાસ્પદ સરનામું /<br /> संदिग्ध पता
                    </label>
                    <textarea
                      onChange={handleInputChange}
                      name="suspect_address"
                      value={formData.suspect_address.textValue}
                      style={{ height: "125px" }}
                      rows="2"
                      cols="20"
                      placeholder="Enter Suspect Address"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="px-2 ms-1 mb-4 mt-2  border-bottom">
                  <h5> Additional Details</h5>
                </div>
                <div className="col-md-3 mb-2">
                  <div className="form-group mb-3">
                    <label
                      htmlFor="social_media_fraud_type"
                      className="form-label fw-semibold"
                    >
                      Police Station / પોલીસ સ્ટેશન / पुलिस स्टेशन
                    </label>
                    <Form.Select
                      onChange={handleInputChange}
                      name="police_station_sr_no"
                      value={formData?.police_station_sr_no?.textValue}
                      className="form-control"
                    >
                      <option value={""} selected="selected">
                        --Select Police Station Type --
                      </option>
                      {policeType?.map((element, index) => (
                        <option key={index} value={element.sr_no}>
                          {element.police_station}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  <div className="form-group mb-3">
                    <label className="form-label fw-semibold">
                      Add Document(Image) / દસ્તાવેજ ઉમેરો / दस्तावेज़ जोड़ें{" "}
                    </label>
                    <input
                      name="file_name"
                      type="file"
                      ref={FileRef}
                      onChange={handleFileChange}
                      accept="image/jpeg, image/png, image/jpg, .pdf,.PDF"
                      className="form-control"
                    />
                    {!formData?.file_name?.valid && (
                      <span className="text-danger">
                        *please upload valid image{" "}
                      </span>
                    )}
                    {/* {!isCancle && state?.RestaurantImage  && <span >{state?.RestaurantImage}</span>} */}
                  </div>
                </div>
                <div className="col-md-3 mb-2">
                  <div className="form-group mb-3">
                    <label
                      htmlFor="social_media_fraud_type"
                      className="form-label fw-semibold"
                    >
                      Media Type / મીડિયા પ્રકાર  / मीडिया प्रकार<span style={{ color: "red" }}>*</span>
                    </label>
                    <Form.Select
                      onChange={handleInputChange}
                      name="media_platform_type_id"
                      value={formData?.media_platform_type_id?.textValue}
                      className="form-control"
                    >
                      <option value={""}>--Select Media Type --</option>

                      {mediaType.map((element, index) => (
                        <option key={index} value={element.type_id}>
                          {element.type_name}
                        </option>
                      ))}
                    </Form.Select>
                 {isSubmit && formData.media_platform_type_id.textValue==0  && <span style={{color:"red"}}> Please Select Media type</span>}
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="fraud_at"
                      className="form-label fw-semibold"
                    >
                      Fraud At / છેતરપિંડી મુ / <br/> धोखाधड़ी पर
                    </label>
                    <input
                      onKeyDown={(e) => e.preventDefault()}
                      onChange={handleInputChange}
                      name="fraud_at"
                      max={maxDate}
                      value={formData.fraud_at.textValue}
                      type="datetime-local"
                      className="form-control"
                      placeholder="Fraud At"
                      // style={{border:`1px solid ${(isSubmit && !formData.sender_bank_name.textValue) ? "red" : isSubmit ? "green" : "light-gray" }  `}}
                    ></input>
                  </div>
                </div>
                <div className="col-md-6 mb-4 ">
                  <div className="form-group">
                    <label className="form-label fw-semibold">Remarks / ટીકા / टिप्पणी</label>
                    <textarea
                      onChange={handleInputChange}
                      name="remarks"
                      value={formData.remarks.textValue}
                      style={{
                        height: "125px",
                        // , border:`1px solid ${(isSubmit && !formData.address.textValue) ? "red" : isSubmit ? "green" : "light-gray" }  `
                      }}
                      rows="2"
                      cols="20"
                      placeholder="Enter Remarks"
                      className="form-control"
                    />
                    {/* {isSubmit && !formData.address.textValue && (
                        <span className="text-danger">*</span>
                      )} */}
                  </div>
                </div>
              </div>
            </form>

            <div className="mt-3">
              <Button
                className="me-2"
                disabled={!validateExpiry}
                onClick={handleSubmit}
                variant="primary"
              >
                Submit
              </Button>{" "}
              <Button
                variant="danger"
                disabled={!validateExpiry}
                onClick={handleCancle}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default SocialMediaFraud;
