import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { IMAGEMODELURL, CYBER_CELL_URL } from '../../Process'
import { useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import UserValidationPage from './ValidateUser'
import { useCookies } from 'react-cookie'
import ValidateModel from './ValidateModel'
import Select from "react-select";

const Form1930 = () => {
  const [cookies, setCookie, removeCookie] = useCookies();

  const navigate = useNavigate()
  const currentTime = new Date();
  let formattedDate = currentTime.toISOString().slice(0, 16)
  const [maxDate, setMaxData] = useState(formattedDate)
  const [policaStationData, setPolicaStationData] = useState([]);
  const location = useLocation();
  const [modalShow, setModalShow] = useState(false);
  const [policeType, SetPoliceType] = useState([]);

  const [isSubmit, setIsSubmit] = useState(false)
  const [validateExpiry, setValidateExpiry] = useState(true)
  const currentUrl = IMAGEMODELURL + location.pathname
  const [formData, setFormData] = useState({
    id: {
      textValue: "",
      required: false,
    },
    register_id: {
      textValue: "",
      required: true,
    },
    mobile_number: {
      textValue: "",
      required: false,
    },
    remarks: {
      textValue: "",
      required: true,
    },
    fraud_at: {
      textValue: "",
      required: false,
    },
    police_station_sr_no: {
      textValue: "",
      required: false,
    },
  });
  useEffect(() => {
    handleGetPoliceStationType();
    handleValidateUrl()
    if (cookies.currentValidUser == 2) {
 
      setModalShow(false)
    } else {
      setModalShow(true)
    }
    
  
  }, [])
  const handleGetPoliceStationType = async () => {
    const res = await fetch(`${CYBER_CELL_URL}police_station/get`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({}),
    });
    const result = await res.json();

    SetPoliceType(result.data);
  };
  const handleValidateUrl = async () => {
    const res = await fetch(`${CYBER_CELL_URL}global_link/validate_link`, {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "link": currentUrl
      })
    })
    const result = await res.json()
    console.log(result, "result")
    if (result.status == false) {
      return navigate("/")
    }
    if (result?.data[0]?.link_expire_at < currentTime.toISOString()) {
      setValidateExpiry(false)
    } else {
      setValidateExpiry(true)
      handleUserData(result?.data[0]?.uid)
    }
  }
  const handleUserData = async (uuid) => {
    const res = await fetch(`${CYBER_CELL_URL}1930/get`, {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "start_date": null,
        "end_date": null,
        "is_pagination": null,
        "per_page": null,
        "id": null,
        "unique_token_id": uuid
      })
    })

    const result = await res.json()
    console.log(result, "finalResult")
    if (result?.data?.length) {
      let formattedDateString;
      if (result?.data[0]?.fraud_at) {
        const dateString = result?.data[0]?.fraud_at
        const dateObject = new Date(dateString);
        formattedDateString = dateObject.toISOString().slice(0, 16)
      }
      setFormData({
        id: {
          textValue: result.data[0]?.id || "",
          required: false,
        },
        register_id: {
          textValue: result.data[0]?.register_id || "",
          required: true,
        },
        mobile_number: {
          textValue: result.data[0]?.mobile_number || "",
          required: false,
        },
        police_station_sr_no: {
          textValue: result.data[0]?.police_station_sr_no || "",
          required: false,
        },
        remarks: {
          textValue: result.data[0]?.remarks || "",
          required: true,
        },
        fraud_at: {
          textValue: formattedDateString || maxDate,
          required: false,
        },

      })
    }


  }
  const handleCancle = () => {
    Swal.fire({
      title: "Do you want to Cancel the changes?",
      // showCancelButton: true,
      buttonsStyling: false,
      showDenyButton: true,
      denyButtonText: `No`,
      confirmButtonText: "Yes",
      confirmButtonColor: "#007AB1",
      denyButtonColor: "white",
      denyButtonBorder: "1px solid black",
      customClass: {
        confirmButton: 'btn btn-primary me-3', // React-Bootstrap primary button style
        denyButton: 'btn btn-danger ',
        title: 'my-custom-title-class'  // React-Bootstrap secondary button style
      },
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // handleDataInsert()
        setFormData((prev) => {
          return {
            id: {
              textValue: prev.id.textValue,
              required: false,
            },
            mobile_number: {
              textValue: prev.mobile_number.textValue,
              required: false,
            },
            police_station_sr_no: {
              textValue: "",
              required: false,
            },
            register_id: {
              textValue: "",
              required: true,
            },
            remarks: {
              textValue: "",
              required: true,
            },
            fraud_at: {
              textValue: maxDate,
              required: false,
            }

          }
        }

        );
      }
    });
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    setIsSubmit(true)
    for(let key in formData){
      if(formData[key].required==true){
        if(!formData[key].textValue){
          return
        }
      }
    }

    console.log(formData, "formData")
  
  
    Swal.fire({
      title: "Do you want to save the changes?",
      showCancelButton: true,
      buttonsStyling: false,

      confirmButtonText: "Save",
      customClass: {
        confirmButton: 'btn btn-primary me-3', // React-Bootstrap primary button style
        cancelButton: 'btn btn-danger '  // React-Bootstrap secondary button style
      },
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        handleDataInsert()
      } else if (result?.isDenied) {
        Swal.fire("Changes are not saved");
      }
    });
  }
  const handleDataInsert = async () => {
    const dateObject = new Date(formData.fraud_at.textValue);


    const res = await fetch(`${CYBER_CELL_URL}1930/update`, {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        id: formData.id.textValue,
        is_submit: 1,
        police_station_sr_no: formData.police_station_sr_no.textValue,
        remarks: formData.remarks.textValue,
        register_id: formData.register_id.textValue,
        fraud_at: `${dateObject.getFullYear()}-${dateObject.getMonth() + 1}-${dateObject.getDate()} ${dateObject.getHours()}:${dateObject.getMinutes()}:${dateObject.getSeconds()}` || maxDate,
      })
    })
    const result = await res.json()
    if (result.status == true) {
      Swal.fire({

        title: "Saved! success",

        buttonsStyling: false,

        confirmButtonText: "Ok",
        customClass: {
          confirmButton: 'btn btn-primary me-3', // React-Bootstrap primary button style

        },
      })

    } else {
      Swal.fire(
        {
          title: `Changes are not saved , ${result.message}`,
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: 'btn btn-primary me-3', // React-Bootstrap primary button style

          },

        });
    }
  }
  const handleInputChange = (e) => {
    let lastInputString = e.target.value[e.target.value.length - 1];
    if (
      e.target.name == "mobile_number"
    ) {
      if (
        e.target.value == "" ||
        (lastInputString.charCodeAt() >= 48 &&
          lastInputString.charCodeAt() <= 57)
      ) {
        setFormData({
          ...formData,
          [e.target.name]: {
            ...formData[e.target.name],
            textValue: e.target.value,
          },
        });
      } else {
        return;
      }
      return
    }

    // let lastInputString = e.target.value[e.target.value.length - 1];

    setFormData({
      ...formData,
      [e.target.name]: {
        ...formData[e.target.name],
        textValue: e.target.value,
      },
    });
  };
  const selectDropDownData1 = (e, action) => {
    console.log(e.value, "epppp");
    setFormData({
      ...formData,
      [action.name]:{
        textValue:e.value,
        required:false
      },
      PoliceStationTempt:{
        textValue:e,
        required:false
      },
    });
  };
  return (
    <div className="container mt-5">
      <ValidateModel modalShow={modalShow} setModalShow={setModalShow} />
      {cookies.currentValidUser === 2 && <div className="row">
        <div style={{ textAlign: "center" }}>
          <h3 style={{ fontSize: "2.4em", fontWeight: "600" }}>
            CYBER CELL SURAT
          </h3>
          <h4 style={{ color: "gray", fontSize: "1.8em", fontWeight: "450" }}>
            Finance Fraud - 1930
          </h4>
        </div>
        <div className="col-md-12">
          <div className=" py-3 mb-3 " >
            <h5 style={{ fontSize: "2em", fontWeight: "400" }}>Registration Form</h5>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="row">
              {/* <div className=" col-md-12 mb-3 border-bottom">
            <h5>Sender Details</h5>
          </div> */}
              <div className="col-md-3 mb-2">
                <div className="form-group mb-3">
                  <label
                    htmlFor="mobile_number"
                    className="form-label fw-semibold"
                  >
                    {" "}
                    Arjadar Mobile Number / અરજદાર મોબાઈલ નંબર / अरजदार मोबाइल नंबर<span style={{ color: "red" }}></span>
                  </label>
                  <input
                    disabled={true}
                    onChange={handleInputChange}
                    name="mobile_number"
                    value={formData.mobile_number.textValue}
                    maxLength={10}
                    type="text"
                    className="form-control "
                    placeholder="Enter Mobile Number "
                  // style={{border:`1px solid ${(isSubmit && (!formData.contact.textValue || formData.contact.textValue.length!==10)) ? "red" : isSubmit ? "green" : "light-gray" }  `}}

                  ></input>
                  {/* {isSubmit && !formData.contact.textValue && (
                      <span className="text-danger">*</span>
                    )}
                    {isSubmit && formData.contact.textValue && formData.contact.textValue.length!==10 && <span className='text-danger'>Please Enter Valid Contact No</span>} */}
                </div>
                <div className="form-group mb-3">
                    <label
                      htmlFor="social_media_fraud_type"
                      className="form-label fw-semibold"
                    >
                      Police Station / પોલીસ સ્ટેશન / पुलिस स्टेशन
                    </label>
                    <Form.Select
                      onChange={handleInputChange}
                      name="police_station_sr_no"
                      value={formData?.police_station_sr_no?.textValue}
                      className="form-control"
                    >
                      <option value={""} selected="selected">
                        --Select Police Station Type --
                      </option>

                      {policeType.map((element, index) => (
                        <option key={index} value={element.sr_no}>
                          {element.police_station}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                <div className="form-group mb-3">
                  <label
                    htmlFor="hotelName"
                    className="form-label fw-semibold"
                  >
                    Register ID / રજીસ્ટર આઈડી / रजिस्टर आईडी <span style={{ color: "red" }}>*</span>
                    {/* <span style={{ color: "red" }}>*</span> */}
        
                  </label>
                  <input
                    onChange={handleInputChange}
                    name="register_id"
                    value={formData.register_id.textValue}
                    type="text"
                    className="form-control"
                    placeholder="Register ID"
                  // style={{border:`1px solid ${(isSubmit && !formData.sender_bank_name.textValue) ? "red" : isSubmit ? "green" : "light-gray" }  `}}

                  ></input>
                   {isSubmit && !formData.register_id.textValue.length && <span style={{color:"red"}} >Please Enter Register ID </span>}

                  {/* {isSubmit && !formData.sender_bank_name.textValue && (
                        <span className="text-danger">*</span>
                      )} */}

                </div>
                <div className="form-group">
                  <label htmlFor="fraud_at" className="form-label fw-semibold">
                  Fraud At / છેતરપિંડી મુ / धोखाधड़ी पर
                    {/*  */}
                  </label>
                  <input
                    onKeyDown={(e) => e.preventDefault()}
                    onChange={handleInputChange}
                    name="fraud_at"
                    max={maxDate}
                    value={formData.fraud_at.textValue}
                    type="datetime-local"
                    className="form-control"
                    placeholder="Fraud At"
                  // style={{border:`1px solid ${(isSubmit && !formData.sender_bank_name.textValue) ? "red" : isSubmit ? "green" : "light-gray" }  `}}
                  ></input>
                  {/* {isSubmit && !formData.sender_bank_name.textValue && (
                        <span className="text-danger">*</span>
                      )} */}
                </div>
              </div>
              <div className="col-md-6 mb-4 ">
                <div className="form-group">
                  <label className="form-label fw-semibold">
                  Remarks / ટીકા / टिप्पणी <span style={{ color: "red" }}>*</span>
                  </label>
                  <textarea
                    onChange={handleInputChange}
                    name="remarks"
                    value={formData.remarks.textValue}
                    style={{
                      height: "210px"
                      // , border:`1px solid ${(isSubmit && !formData.address.textValue) ? "red" : isSubmit ? "green" : "light-gray" }  ` 
                    }}
                    rows="2"
                    cols="20"
                    placeholder="Enter Remarks"
                    className="form-control"
                  />
                     {isSubmit && !formData.remarks.textValue.length && <span style={{color:"red"}} >Please Enter Remarks</span>}
                  {/* {isSubmit && !formData.address.textValue && (
                        <span className="text-danger">*</span>
                      )} */}
                </div>
              </div>
            </div>
            <div className='mt-3'>
              <Button className='me-2' disabled={!validateExpiry} onClick={handleSubmit} variant="primary"  >
                Submit
              </Button>{" "}
              <Button variant="danger" disabled={!validateExpiry} onClick={handleCancle} >
                Cancel
              </Button>
            </div>
          </form>

        </div>


      </div>}
    </div>
  )
}

export default Form1930