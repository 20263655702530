import React from 'react'
import { Modal } from 'react-bootstrap'
import UserValidationPage from './ValidateUser'

const ValidateModel = ({modalShow,setModalShow}) => {
  return (
    <Modal
    fullscreen={true}
    // {...props}
    show={modalShow}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header>
    </Modal.Header>
    <Modal.Body>
    <UserValidationPage setModalShow={setModalShow}  />
    </Modal.Body>
  </Modal>
  )
}

export default ValidateModel