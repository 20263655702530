export const handleKeyDownNumber = (event) => {
    const charCode = event.which || event.keyCode;
    if (event.key === 'v' && (event.ctrlKey || event.metaKey)) {
      return; // Allow pasting
    }
    if (event.key === 'c' && (event.ctrlKey || event.metaKey)) {
      return; // Allow copying
    }
    if (!/\d/.test(event.key) && event.key !== "Backspace" && event.key !== "Delete" && charCode !== 9) {
      event.preventDefault();
    }
  };

  
  export const handlePaste = (event) => {
    const pastedText = event.clipboardData.getData("text/plain");
  
    const isValid = /^\d+$/.test(pastedText); 
    if (!isValid) {
      event.preventDefault();
    }
  };