import React, { useEffect, useState } from 'react'
import { CYBER_CELL_URL } from '../Process';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { FaCircleDot } from 'react-icons/fa6';
import Select from "react-select";
import { RotatingLines } from 'react-loader-spinner';
import EyeOpen from "../Assets/eyeopen.png";
import EyeClose from "../Assets/eyeclose.png";
import moment from 'moment';
import { SocialMediaTypeReport } from '../Constants';

const SocialMediaBasedType = () => {
  const [data,setData]=useState([])
  const [pageId,setPageId]=useState(1)
  const [mainTableData,setMainTableData]=useState([])
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState();
  const [pageCount,setPageCount]=useState(10)
  const [tableData,setTableData]=useState([])
  const [policaStationData, setPolicaStationData] = useState([]);
  const [inputVal,setInputValue]=useState("")
  const [filterData, setFilterdata] = useState({

    PoliceStationName: "",
    PoliceStationTempt: "",
  });
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    fetchMostFraudReportsData();
  }, []);
  const fetchMostFraudReportsData=async()=>{
    setIsLoading(true);
    try {
      const res = await fetch(`${CYBER_CELL_URL}/report/based_on_social_media_type`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
        }),
      });
      const result = await res.json();


if(result.status==true){
  const tempt = [];

  result.data.forEach((element) => {
    tempt.push({
      obj: element,
      FraudCountArrIsDetails: false,
      FraudCountArr: [],
    });
  });
      console.log(result, "result");
      setData(tempt);
      setTotalCount(tempt.length);
      setMainTableData(tempt);
      setTableData(tempt.slice(0, pageCount));
      setPolicaStationData(
        tempt.map((item) => ({
          value: item.obj.fraud_type,
          label: item.obj.fraud_type,
        }))
      );
      setIsLoading(false);
    }

    } catch (err) {
      console.log(err);
    }
  }
  const selectDropDownData1 = (e, action) => {

    setFilterdata({
      [action.name]: e.value,
      PoliceStationTempt: e,
    });

  };
  const handleNextPage=()=>{
     console.log(data,"data")
 if(tableData.length<pageCount){
  return 
 }
    const temptData=data.slice((pageId)*pageCount,((pageId)*pageCount)+pageCount)
    setTableData(temptData)
    setPageId(pageId+1)
  }
  const handlePreviousPage=()=>{
    if(pageId==1){
      return 
    }
    const temptData=data.slice((pageId-2)*pageCount,((pageId-2)*pageCount)+pageCount)
    setTableData(temptData)
    setPageId(pageId-1)

  }
  const handlePageCount=(e)=>{
    console.log(e.target.value,"e.target.value")
    setPageCount(parseInt(e.target.value))
    searchHandle(parseInt(e.target.value))
  }
  const searchHandle=(pageCount)=>{

const filterdata=mainTableData.filter((element,index)=>{
  return element?.obj.fraud_type?.includes(filterData.PoliceStationTempt.value || "")
})
setData(filterdata)
setTableData(filterdata.slice(0,pageCount))
setPageId(1)
setPageCount(pageCount)
  }
  const handleCancle=()=>{
    setTableData(mainTableData.slice(0,pageCount))
    setData(mainTableData)
    setPageId(1)
    setFilterdata({
      PoliceStationName: "",
      PoliceStationTempt: "",
    })
  }
  const handleInputChange=(e)=>{
    setInputValue(e)
    searchHandle(e)
  }
  const handleEyeOnFetch =async(path,fraudType)=>{
    try {
      const res = await fetch(
        `${CYBER_CELL_URL}/${path}`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            "media_platform_type_id" : fraudType
          }),
        }
      );
      const result = await res.json();

      if (result.status == true && result.data) {
      return result.data
      }
    }catch(err){
      return []
    }
  }
  const handleEyeOn = async(index, isDetails,isDetailsArr,path,fraudType) => {
    console.log("eyeon");
    const tempt = [...tableData];
    if(!tempt[index][isDetailsArr].length){
      const finalRes=await handleEyeOnFetch(path,fraudType)
      tempt.splice(index, 1, {
        ...tableData[index],
        [isDetailsArr] :finalRes,
        [isDetails]: true
      });
      console.log(finalRes,"finalRes")

    }else{
    tempt.splice(index, 1, {
      ...tableData[index],

      [isDetails]: true,
    });
  }
    setTableData(tempt);
  };
  const handleEyeOff = (index, isDetails) => {
    const tempt = [...tableData];
    tempt.splice(index, 1, {
      ...tableData[index],
      [isDetails]: false,
    });
    setTableData(tempt);
  };
  return (
    <div className="body-wrapper">
   <div className="container-fluid">
          {isLoading && (
            <div
              style={{
                position: "absolute",
                zIndex: "6",
                left: "600px",
                top: "300px",
              }}
            >
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="200"
                height="200"
                visible={true}
              />
            </div>
          )}
            <h4>{SocialMediaTypeReport}</h4>
            <div>
            <div
            className={` pt-2 pb-2 place-filter  ${isOpen ? "open" : "closed"}`}
          >
            <Row>
            <Col md={2} sm={2} className="col-4">
                <div className="d-flex gap-2 pt-1">
                  <span>Total Count :</span>
                  <span>{totalCount}</span>
                </div>
              </Col>
              <Col  md={2} sm={2} className="col-4">
                <Form.Select
                  onChange={handlePageCount}
                  size="sm"
                  value={pageCount}
                  className="w-70"
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                </Form.Select>
              </Col>
              <Col md={{ span: 2, offset: 6 }} className="col-4">
                <Button
                  className="float-end"
                  md={2}
                  variant="outline-primary"
                  size="sm"
                  onClick={handleToggle}
                >
                  {isOpen ? "Close Filter" : "Open Filter"}
                </Button>
              </Col>
            </Row>
            </div>
           {isOpen && <div
              className="filter-content"
              style={{
                width:"100%",
                boxShadow: "2px 1px 15px",
                padding: "20px",
                backgroundColor: "white",
                borderRadius: "5px",
                textAlign: "center",
                margin: "2em auto",
                border: "1px solid gray",
              }}
            >
              <Form className="d-flex gap-4 pb-3 ">
                <div className="form-group col-md-3 mb-2">
                  <label
                    htmlFor="PoliceStationName"
                    className="form-label fw-semibold"
                  >
                    {" "}
                    Social Media Type
                  </label>
                  <Select
                    name="PoliceStationName"
                    placeholder="--Select Social Media Type--"
                    onChange={selectDropDownData1}
                    value={filterData.PoliceStationTempt}
                    options={[
                      { label: "Select Social Media Type ", value: "" },
                      ...policaStationData,
                    ]}
                  />
                </div>
                <div style={{ position: "relative", top: "27px" }}>
                  <Button
                    className="me-2 mb-2"
                    onClick={()=>searchHandle(pageCount)}
                    
                    variant="success"
                  >
                    Apply
                  </Button>
                  <Button className="me-2 mb-2" onClick={handleCancle} variant="danger">
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>}
            <div className="overflow-auto">
          <Table
            style={{
              boxShadow: "2px 3px 15px",
              textAlign: "left",
              border: "1px solid gray",
            }}
            striped
            bordered
            hover
            
          >
            <thead>
              <tr>
                <th>Sr.No</th>
                <th>Fraud Type</th>
                <th>Fraud Count</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((element, index) => (
                <>
                  <tr>
                    <td style={{width:"3%"}}>{(pageId - 1) * pageCount + (index + 1)}</td>
                    <td style={{width:"15%"}} >
                      {element?.obj.fraud_type}
                    </td>
                    <td>
                        {element?.obj.count}
                        {parseInt(element?.obj.count) ? (
                          <span>
                            <SocialMediaBasedFraudTableDetailsEyeFunctionality
                              isDetails={element.FraudCountArrIsDetails}
                              handleEyeOn={handleEyeOn}
                              isDetailsArr="FraudCountArr"
                              path="social_media_fraud/get"
                              fraudType={element.obj.media_platform_type}
                              detailTitle="FraudCountArrIsDetails"
                              handleEyeOff={handleEyeOff}
                              index={index}
                            />
                          </span>
                        ) : (
                          ""
                        )}
                      </td>
                  </tr>
                  {element.FraudCountArrIsDetails && (
                      <tr>
                        <td colSpan={10}>
                          <Table
                            style={{
                              textAlign: "left",
                              border: "1px solid gray",
                            }}
                            striped
                            bordered
                            hover
                          >
                            <thead>
                              <tr>
                                <th>Complainer Name</th>
                                <th>Complainer Mobile Number</th>
                                <th>Fraud At</th>
                                <th>Remarks</th>
                              </tr>
                            </thead>
                            <tbody>
                              {element.FraudCountArr.map((element,index)=>(
                                <tr key={index}>
                                        <td style={{ width: "20%" }}>
                                          {element?.username}
                                        </td>
                                        <td>{element.mobile_number}</td>
                                        <td>{element?.fraud_at
                                            ? moment(
                                                element?.fraud_at
                                              ).format("DD-MM-YYYY")
                                            : ""}</td>
                                        <td>{element.remarks}</td>
                                      </tr>
                              ))}
                                      
                                    </tbody>
                          </Table>
                        </td>
                      </tr>
                    )}
                </>
              ))}
            </tbody>
            <tbody>
              <tr>
                {!tableData.length && (
                  <td colSpan={11}>
                    <div
                      style={{
                        textAlign: "center",
                        padding: "20px",
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "#ff0000",
                        backgroundColor: "#f0f0f0",
                        border: "1px solid #e0e0e0",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                    >
                      NO DATA FOUND
                    </div>
                  </td>
                )}
              </tr>
            </tbody>
          </Table>
          </div>
          </div>
          <div className="pt-2 pb-2  ">
            <Button variant="primary" onClick={handlePreviousPage} size="sm">
              PREVIOUS
            </Button>{" "}
            <Button variant="secondary" onClick={handleNextPage} size="sm">
              NEXT
            </Button>
          </div>
        </div>
    </div>
    
  )
}

export default SocialMediaBasedType

const SocialMediaBasedFraudTableDetailsEyeFunctionality = ({
  isDetails,
  handleEyeOn,
  handleEyeOff,
  isDetailsArr,
  path,
  fraudType,
  detailTitle,
  index,
}) => {
  return (
    <span className="ms-2">
      {" "}
      {!isDetails && (
        <img
          onClick={() =>
            handleEyeOn(index, detailTitle, isDetailsArr, path, fraudType)
          }
          src={EyeOpen}
          height={"18px"}
          alt=""
          className="cursor-pointer"
        />
      )}{" "}
      {isDetails && (
        <img
          className="cursor-pointer"
          onClick={() => handleEyeOff(index, detailTitle)}
          src={EyeClose}
          height={"18px"}
          alt=""
        />
      )}
    </span>
  );
};