import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CYBER_CELL_URL, IMAGEMODELURL } from "../Process";
import { Button, Col, Row } from "react-bootstrap";
import { Form, Table } from "react-bootstrap";
import XLSX from "xlsx-color";
import {handleKeyDownNumber, handlePaste} from "../component/CommonMethod"

import { FaCircleDot, FaLink } from "react-icons/fa6";
import EyeOpen from "../Assets/eyeopen.png";
import ImageNotFound from "../Assets/Noimage.png";

import { MdChangeCircle } from "react-icons/md";

import moment from "moment";
import { RotatingLines } from "react-loader-spinner";
import EyeClose from "../Assets/eyeclose.png";
import ChangeStatusModal from "./ChangeStatus";
import Swal from "sweetalert2";
import Select from "react-select";
import { useCookies } from "react-cookie";


const SocialMedia = () => {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies();
  const [totalCount, setTotalCount] = useState();
  const [pageCount, setPageCount] = useState("10");
  const currentTime = new Date();
  let formattedDate = currentTime.toISOString().slice(0, 16);
  const [maxDate, setMaxData] = useState(formattedDate);
  const [tableData, setTableData] = useState([]);
  const [fraudType, setFraudType] = useState([]);
  const [hotelData, setHotelData] = useState([]);
  const [currentStatus, setCurrentStatus] = useState(false);
  const [allTableData, setAllTableData] = useState([]);
  const [openModel, setOpenModel] = useState(false);
  const [currentObj, setCurrentObj] = useState({});
  const [changePasswordID, setChangePasswordID] = useState("");
  const [pageId, setPageId] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [validDate, setValidDate] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [policaStationData, setPolicaStationData] = useState([]);
  const [filterData, setFilterdata] = useState({
    StartData: new Date().toISOString().split("T")[0],
    EndDate: new Date().toISOString().split("T")[0],
    mobile_number : "",
    PoliceStationName: "",
    PoliceStationTempt: "",
  });
  useEffect(() => {
    GetPoliceStation();
    handleGetFraudType();
    fetchInitialPlaceDetailsData(pageCount, filterData);
  }, []);
  const GetPoliceStation = async () => {
    try {
      const res = await fetch(`${CYBER_CELL_URL}user/get_police_station`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          // user_id:cookies.ckUserID,
          user_id: cookies.ckUserID,
        }),
      });
      const result = await res.json();
      console.log(result, "result");

      setPolicaStationData(
        result.data.map((item) => ({
          value: item.sr_no,
          label: item.police_station,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };
  const handleChangeStatus = (changeID, currentStatus, obj) => {
    console.log(changeID, "changeID");
    setOpenModel(true);
    setChangePasswordID(changeID);
    setCurrentStatus(currentStatus);
    setCurrentObj(obj);
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const handleGetFraudType = async () => {
    const res = await fetch(`${CYBER_CELL_URL}social_media_fraud_type/get`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        user_id: cookies.ckUserID,
      }),
    });
    const result = await res.json();

    setFraudType(result.data);
  };
  const fetchInitialPlaceDetailsData = async (count, filterData) => {
    if (!validDate) {
      return;
    }
    console.log(
      filterData?.StartData?.split("-").reverse().join("-"),
      filterData?.EndDate?.split("-").reverse().join("-"),
      "jshfjasjd"
    );
    console.log(
      filterData?.StartData?.split("-").reverse().join("-"),
      "filterData?.StartData?.split(" - ").reverse().join(" - ")"
    );
    setIsLoading(true);
    try {
      const res = await fetch(`${CYBER_CELL_URL}/social_media_fraud/get`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          start_date: filterData?.StartData?.split("-").reverse().join("-"),
          end_date: filterData?.EndDate?.split("-").reverse().join("-"),
          is_pagination: true,
          mobile_number : filterData.mobile_number,
          police_station: filterData.PoliceStationName,
          user_id: cookies.ckUserID,
          per_page: count,
          id: null,
          unique_token_id: null,
        }),
      });
      const result = await res.json();
      // setTotalCount(result.total[0].TotalCount)
      console.log(result, "initialReasult");
      const tempt = [];
      result.data.forEach((element) => {
        tempt.push({
          obj: element,
          isDetails: false,
        });
      });
      setIsLoading(false);
      setTableData(tempt);
      setPageId(1);
      setAllTableData(tempt);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  const handlePageCount = (e) => {
    setPageCount(e.target.value);
    // fetchInitialPlaceDetailsData(e.target.value,filterData)
    searchHandle(e.target.value, filterData);
  };
  const handleEyeOn = (index) => {
    console.log("eyeon");
    const tempt = [...tableData];
    tempt.splice(index, 1, {
      ...tableData[index],
      isDetails: true,
    });
    setTableData(tempt);
  };
  const handleEyeOff = (index) => {
    const tempt = [...tableData];
    tempt.splice(index, 1, {
      ...tableData[index],
      isDetails: false,
    });
    setTableData(tempt);
  };
  const handleInputChange = (e) => {
    if (e.target.name == "StartData") {
      if (e.target.value > filterData.EndDate) {
        setValidDate(false);
      } else {
        setValidDate(true);
      }
    } else if (e.target.name == "EndDate") {
      if (filterData.StartData > e.target.value) {
        setValidDate(false);
      } else {
        setValidDate(true);
      }
    }

    console.log(
      e.target.value.split("-").reverse().join("-"),
      "e.target.value"
    );
    setFilterdata({
      ...filterData,
      [e.target.name]: e.target.value,
    });
  };
  const searchHandle = async (count, filterData) => {
    if (!validDate) {
      return;
    }
    console.log(
      filterData?.StartData?.split("-").reverse().join("-"),
      filterData?.EndDate?.split("-").reverse().join("-"),
      "jshfjasjd"
    );
    setIsLoading(true);
    try {
      console.log(filterData, "filterDataaaaaaaaa", count);
      const res = await fetch(`${CYBER_CELL_URL}/social_media_fraud/get`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          start_date: filterData?.StartData?.split("-").reverse().join("-"),
          end_date: filterData?.EndDate?.split("-").reverse().join("-"),
          police_station: filterData.PoliceStationName,
          mobile_number : filterData.mobile_number,
          user_id: cookies.ckUserID,
          is_pagination: true,
          per_page: count,
          id: null,
          unique_token_id: null,
        }),
      });
      const result = await res.json();
      console.log(result);
      // setTotalCount(result.total[0].TotalCount);
      const tempt = [];
      result.data.forEach((element) => {
        tempt.push({
          obj: element,
          isDetails: false,
        });
      });
      console.log(tempt, "?tempt");
      setTableData(tempt);
      setIsLoading(false);
      setAllTableData(tempt);
      setPageId(1);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  const handleCancle = () => {
    let tempt = {
      StartData: new Date().toISOString().split("T")[0],
      EndDate: new Date().toISOString().split("T")[0],
    };
    setFilterdata(tempt);
    setValidDate(true);

    fetchInitialPlaceDetailsData(pageCount, tempt);
  };
  // const handleLink=(link)=>{
  //   if()
  // }
  //   const handleFilterInputChange = (e) => {
  //     let tempt={...filterData}
  //      tempt={
  //       ...filterData,
  //       [e.target.name]: e.target.value,
  //     }
  //     setFilterdata(tempt);
  //     searchHandle(pageCount,0,tempt)
  //   };
  //   const handleHotelBookingStatus=async(hotelId,status)=>{
  //     try{
  //     console.log(hotelId,"placeID")
  //   const res=await fetch(`${CYBER_CELL_URL}/Hotel/BookingHotelStatus`,{
  //     method:"post",
  //     headers:{
  //       "Content-Type":"application/json"
  //     },
  //     body:JSON.stringify({
  //       HotelID: hotelId,
  //       IsAllowBooking:`${status}`
  //     })
  //   })
  //   const result=await res.json()
  //   fetchHotelUserData()
  //   console.log(result,"placeActiveStutus")
  // }catch(err){
  //   console.log(err)
  // }
  //   }

  const setNextApi = async () => {
    console.log(pageId, "pageID", pageCount);
    if (pageId * pageCount < allTableData.length) {
      const tempt = [...allTableData];
      console.log(tempt, pageId * pageCount, (pageId + 1) * pageCount);
      const sliced = tempt.slice(pageId * pageCount, (pageId + 1) * pageCount);
      setTableData(sliced);
      setPageId(pageId + 1);
      return;
    }
    const paginationIndex = allTableData[pageId * pageCount - 1]?.obj?.id;
    if (!paginationIndex) {
      return;
    }
    try {
      const res = await fetch(`${CYBER_CELL_URL}/social_media_fraud/get`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          start_date: filterData?.StartData?.split("-").reverse().join("-"),
          end_date: filterData?.EndDate?.split("-").reverse().join("-"),
          police_station: filterData.PoliceStationName,
          mobile_number : filterData.mobile_number,
          user_id: cookies.ckUserID,
          is_pagination: true,
          per_page: pageCount,
          unique_id: paginationIndex,
        }),
      });
      const result = await res.json();
      console.log(result);
      const tempt = [];
      result.data.forEach((element) => {
        tempt.push({
          obj: element,
          isDetails: false,
        });
      });
      console.log(tempt, "tempt");
      setTableData(tempt);
      setPageId(pageId + 1);
      setAllTableData([...allTableData, ...tempt]);
    } catch (err) {
      console.log(err, "err");
    }
  };

  const setPreviousApi = () => {
    if (pageId == 1) {
      return;
    }
    const tempt = [...allTableData];
    console.log(pageId, "pageId");
    console.log(
      pageId,
      tempt,
      (pageId - 1) * pageCount,
      pageId * pageCount + 1
    );
    const sliced = tempt.slice(
      (pageId - 2) * pageCount,
      (pageId - 1) * pageCount
    );
    setTableData(sliced);
    setPageId(pageId - 1);
  };
  // async function fetchExcelData() {
  //   // setIsLoading(true)
  //   setIsLoading(true);
  //   try {
  //     const res = await fetch(`${CYBER_CELL_URL}/Place/GetPlace`, {
  //       method: "post",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         PlaceName: filterData.place,
  //         PaginationID: "-1",
  //         PageLength: ``,
  //       }),
  //     });
  //     const result = await res.json();
  //     handleDownloadExcel(result.data);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }
  // const handleDownloadExcel = (array) => {
  //   console.log(array, "array");
  //   const header = [
  //           "Status",
  //           "Mobile Number",
  //           "Social Media Fraud Type",
  //           "Gender",
  //           "Birth Date",
  //           "Username",
  //           "Address",
  //           "Suspect Name",
  //           "Suspect Address",
  //           "Suspect Mobile Number",
  //           "suspect_mail_address",
  //           "suspect_profile_link",
  //           "remarks",
  //           "fraud_at",
  //           "mail_address",
  //           "social_media_fraud_type",
  //           "police_station_details",
  //           "media_platform_details"
  //   ];
  //   const body = [];
  //   array?.forEach((element, index) => {
  //     body.push([
  //       element.PlaceName,
  //       element.StartTime,
  //       element.EndTime,
  //       element.VideoLink,
  //       element.PlaceAddress,
  //       element.PlaceLatitude,
  //       element.PlaceLongitude,
  //       element.Description,
  //       element.Instruction,
  //       element.TransportDetail,
  //     ]);
  //   });
  //   if (!body.length) {
  //     setIsLoading(false);
  //     return Swal.fire({
  //       title: "No Data Found!",
  //       icon: "error",
  //       timer: 1500,
  //     });
  //   }
  //   //   setIsLoading(false)

  //   const ws1 = XLSX.utils.aoa_to_sheet([header, ...body]);

  //   header.forEach((col, index) => {
  //     ws1[XLSX.utils.encode_cell({ r: 0, c: index })].s = {
  //       font: { bold: true, color: { rgb: "f7f4ef" } }, // White font color
  //       fill: { patternType: "solid", fgColor: { rgb: "54153255" } }, // Blue background color
  //       alignment: { wrapText: true, horizontal: "center" },
  //     };
  //   });

  //   ws1["!cols"] = [
  //     { width: 30 },
  //     { width: 10 },
  //     { width: 10 },
  //     { width: 40 },
  //     { width: 40 },
  //     { width: 10 },
  //     { width: 10 },
  //     { width: 40 },
  //     { width: 40 },
  //     { width: 40 },
  //   ];

  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws1, "Place Details");

  //   // Save the workbook
  //   setIsLoading(false);
  //   XLSX.writeFile(
  //     wb,
  //     `Place_Details${moment().format("DD-MM-YYYY_HH:mm")}.xlsx`
  //   );
  // };
  // const selectFilterData=()=>{

  // }
  const handlePlaceActive = async (hotelId, status) => {
    try {
      console.log(hotelId, "placeID");
      const res = await fetch(`${CYBER_CELL_URL}/Hotel/UpdateActiveStatus`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          HotelID: hotelId,
          Status: `${status}`,
        }),
      });
      const result = await res.json();
      // searchHandle(pageCount,0,filterData)
      console.log(result, "placeActiveStutus");
    } catch (err) {
      console.log(err);
    }
  };
  if (isLoading) {
    return (
      <div
        style={{
          position: "absolute",
          zIndex: "6",
          left: "600px",
          top: "300px",
        }}
      >
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="200"
          height="200"
          visible={true}
        />
      </div>
    );
  }
  const selectDropDownData1 = (e, action) => {
    console.log(e.value, "epppp");
    setFilterdata({
      ...filterData,
      [action.name]: e.value,
      PoliceStationTempt: e,
    });
  };
  async function fetchExcelData() {
    // setIsLoading(true)
    setIsLoading(true);
    try {
      const res = await fetch(`${CYBER_CELL_URL}/social_media_fraud/get`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          start_date: filterData?.StartData?.split("-").reverse().join("-"),
          end_date: filterData?.EndDate?.split("-").reverse().join("-"),
          police_station: filterData.PoliceStationName,
          mobile_number : filterData.mobile_number,
          user_id: cookies.ckUserID,
          is_pagination: false,
          per_page: null,
          id: null,
          unique_token_id: null,
        }),
      });
      const result = await res.json();
      handleDownloadExcel(result.data);
    } catch (err) {
      console.log(err);
    }
  }
  const handleDownloadExcel = (array) => {
    console.log(array, "array");
    const header = [
      "Name",
      "Status",
      "Remarks",
      "Mobile Number",
      "Gender",
      "Mail Address",
      "Address",
      "Birth Date",
      "Suspect Name",
      "Suspect Mobile Number",
      "Suspect Mail Address",
      "Suspect Profile Link",
      "Suspect Address",
      "Police StationType",
      "Media Type",
      "Fraud At",
      "Remarks"

    ];
    const body = [];
    array?.forEach((element, index) => {
      body.push([
        element?.username,
        element?.status == 0
                          ? "Pending"
                          : element?.status == 1
                          ? "In Progress"
                          : element?.status == 2
                          ? "Complete"
                          : "",
        element?.remarks,
        element?.mobile_number,
        element?.gender==0 ? "Male" : "Female",
        element?.mail_address,
        element?.address,
        element?.birth_date
        ? moment(
            element?.birth_date
          ).format("DD-MM-YYYY")
        : "",
        element?.suspect_name,
        element?.suspect_mobile_number,
        element?.suspect_mail_address,
        element?.suspect_profile_link,
        element?.suspect_address,
        element?.police_station_details?.[0]?.police_station,
        element?.police_station_details?.[0]?.type_name,
        element?.fraud_at
      ]);
    });
    console.log(body,"bodybody")
    if (!body.length) {
      setIsLoading(false);
      return Swal.fire({
        title: "No Data Found!",
        icon: "error",
        timer: 1500,
      });
    }
    //   setIsLoading(false)

    const ws1 = XLSX.utils.aoa_to_sheet([header, ...body]);

    header.forEach((col, index) => {
      ws1[XLSX.utils.encode_cell({ r: 0, c: index })].s = {
        font: { bold: true, color: { rgb: "f7f4ef" } }, // White font color
        fill: { patternType: "solid", fgColor: { rgb: "54153255" } }, // Blue background color
        alignment: { wrapText: true, horizontal: "center" },
      };
    });

    ws1["!cols"] = [
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 10 },
      { width: 20 },
      { width: 30 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
    ];

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws1, "Social Media Details");

    // Save the workbook
    setIsLoading(false);
    XLSX.writeFile(
      wb,
      `Social Media${moment().format("DD-MM-YYYY_HH:mm")}.xlsx`
    );
  };
  return (
    <>
      <div className="body-wrapper">
        <div className="container-fluid">
          {isLoading && (
            <div
              style={{
                position: "absolute",
                zIndex: "6",
                left: "600px",
                top: "300px",
              }}
            >
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="200"
                height="200"
                visible={true}
              />
            </div>
          )}
          {openModel && (
            <ChangeStatusModal
              apiRoute="social_media_fraud"
              filterData={filterData}
              fetchInitialPlaceDetailsData={fetchInitialPlaceDetailsData}
              pageCount={pageCount}
              openModel={openModel}
              setOpenModel={setOpenModel}
              id={changePasswordID}
              currentStatus={currentStatus}
              currentObj={currentObj}
            />
          )}
          <div
            className={` pt-2 pb-2 place-filter  ${isOpen ? "open" : "closed"}`}
          >
            <Row>
              <Col md={2}>
                <div className="d-flex gap-2 pt-1">
                  <span>Total Count</span>
                  <span>{totalCount}</span>
                </div>
              </Col>
              <Col md={2}>
                <Form.Select
                  onChange={handlePageCount}
                  size="sm"
                  value={pageCount}
                  className="w-50"
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                </Form.Select>
              </Col>

              <Col md={{ span: 2, offset: 6 }}>
                <Button
                  className="float-end"
                  md={2}
                  variant="outline-primary"
                  size="sm"
                  onClick={handleToggle}
                >
                  {isOpen ? "Close Filter" : "Open Filter"}
                </Button>
              </Col>
            </Row>
          </div>
          {isOpen && (
            <div
              className="filter-content"
              style={{
                boxShadow: "2px 1px 15px",
                padding: "20px",
                backgroundColor: "white",
                borderRadius: "5px",
                textAlign: "center",
                margin: "2em auto",
                border: "1px solid gray",
              }}
            >
              <Form className="d-flex gap-4 ">
                <div className="form-group col-md-2 mb-2">
                <label

                    className="form-label fw-semibold"
                  >
                    {" "}
                    Mobile Number
                  </label>
                  <input type="text"
                  className="form-control"
                  name="mobile_number"
                  onChange={handleInputChange}
                  value={filterData.mobile_number}
                  placeholder="Enter Mobile Number"
                  maxLength={10}
                  onKeyDown={handleKeyDownNumber}
                  onPaste={handlePaste}
                  
                  />
                </div>
                <div className="form-group col-md-2 mb-2">
                  <label
                    htmlFor="PoliceStationName"
                    className="form-label fw-semibold"
                  >
                    {" "}
                    Police Station
                  </label>
                  <Select
                    name="PoliceStationName"
                    placeholder="--Select Police Staion --"
                    onChange={selectDropDownData1}
                    value={filterData.PoliceStationTempt}
                    options={[
                      { label: "--Select Police Station --", value: "" },
                      ...policaStationData,
                    ]}
                  />
                </div>
                <div className="form-group col-md-2">
                  <label htmlFor="fraud_at" className="form-label fw-semibold">
                    Start Date
                    {/*  */}
                  </label>
                  <input
                    onKeyDown={(e) => e.preventDefault()}
                    onChange={handleInputChange}
                    name="StartData"
                    max={new Date().toISOString().split("T")[0]}
                    value={filterData.StartData}
                    type="date"
                    className="form-control"
                    placeholder="Fraud At"
                    // style={{border:`1px solid ${(isSubmit && !formData.sender_bank_name.textValue) ? "red" : isSubmit ? "green" : "light-gray" }  `}}
                  ></input>
                  {/* {isSubmit && !formData.sender_bank_name.textValue && (
                        <span className="text-danger">*</span>
                      )} */}
                </div>
                <div className="form-group col-md-2">
                  <label htmlFor="fraud_at" className="form-label fw-semibold">
                    End Date
                    {/*  */}
                  </label>
                  <input
                    onKeyDown={(e) => e.preventDefault()}
                    onChange={handleInputChange}
                    name="EndDate"
                    max={new Date().toISOString().split("T")[0]}
                    value={filterData.EndDate}
                    type="date"
                    className="form-control"
                    placeholder="Fraud At"
                    // style={{border:`1px solid ${(isSubmit && !formData.sender_bank_name.textValue) ? "red" : isSubmit ? "green" : "light-gray" }  `}}
                  ></input>
                  {!validDate && (
                    <span className="text-danger" style={{ textAlign: "left" }}>
                      End Date must be Greater than start Date
                    </span>
                  )}
                </div>

                <div style={{ position: "relative", top: "27px" }}>
                  <Button
                    variant="primary"
                    className="me-2 "
                    onClick={() => {
                      searchHandle(pageCount, filterData);
                    }}
                  >
                    Apply
                  </Button>
                  <Button
                    className="me-2"
                    onClick={fetchExcelData} variant="success">
                    Export
                  </Button>
                  <Button onClick={handleCancle} variant="danger">
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>
          )}
          <Table
            style={{
              boxShadow: "2px 3px 15px",
              textAlign: "left",
              border: "1px solid gray",
            }}
            striped
            bordered
            hover
          >
            <thead>
              <tr>
                <th>Sr.No</th>
                <th>Status</th>
                <th>Complainer Mobile Number</th>
                <th>Complainer Name</th>
                <th>Remarks</th>
                <th>Details</th>
                <th>Edit Status And Remark</th>
                {/* <th>Gender</th>
              <th>Mail Address</th>
              <th>Address</th>
              <th>Date Of Birth</th> */}
              </tr>
            </thead>
            <tbody>
              {tableData.map((element, index) => (
                <>
                  <tr>
                    <td>{(pageId - 1) * pageCount + (index + 1)}</td>
                    <td style={{ width: "10%" }}>
                      {" "}
                      <FaCircleDot
                        style={{
                          color:
                            element.obj.status == 0
                              ? "red"
                              : element.obj.status == 1
                              ? ""
                              : element.obj.status == 2
                              ? "green"
                              : "",
                          fontSize: "1em",
                        }}
                      />
                      <span className="ms-2">
                        {element.obj.status == 0
                          ? "Pending"
                          : element.obj.status == 1
                          ? "In Progress"
                          : element.obj.status == 2
                          ? "Complete"
                          : ""}
                      </span>
                      {/* {element.obj.IsActive ? (
                      <FaToggleOn 
                        style={{ color: "green", fontSize: "1.9em" }}
                      />
                    ) : (
                      <FaToggleOff onClick={()=>handlePlaceActive(element.obj.HotelID,1)}
                        style={{ color: "red", fontSize: "1.8em" }}
                      />
                    )} */}
                    </td>
                    <td style={{ width: "13%" }}>
                      {element?.obj?.mobile_number}
                    </td>
                    <td style={{ width: "20%" }}>{element?.obj?.username}</td>
                    <td style={{ width: "40%" }}>{element?.obj?.remarks}</td>
                    <td>
                      {!element.isDetails && (
                        <img
                          onClick={() => handleEyeOn(index)}
                          src={EyeOpen}
                          height={"22px"}
                          alt=""
                        />
                      )}{" "}
                      {element.isDetails && (
                        <img
                          onClick={() => handleEyeOff(index)}
                          src={EyeClose}
                          height={"22px"}
                          alt=""
                        />
                      )}
                    </td>
                    <td>
                      <MdChangeCircle
                        onClick={() => {
                          handleChangeStatus(
                            element?.obj?.id,
                            element?.obj?.status,
                            element?.obj
                          );
                        }}
                        style={{ fontSize: "1.9em" }}
                      />
                    </td>
                  </tr>
                  {element.isDetails && (
                    <tr>
                      <td colSpan={10}>
                        <Table
                          style={{
                            textAlign: "left",
                            border: "1px solid gray",
                          }}
                          striped
                          bordered
                          hover
                        >
                          <thead>
                            <th className="p-2">User Details</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {
                                  <Table
                                    style={{
                                      textAlign: "left",
                                      border: "1px solid gray",
                                    }}
                                    striped
                                    bordered
                                    hover
                                  >
                                    <thead>
                                      <tr>
                                        <th>Name</th>
                                        <th>Gender</th>
                                        <th>Mail Address</th>
                                        <th>Address</th>
                                        <th>Date Of Birth</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td style={{ width: "20%" }}>
                                          {element?.obj?.username}
                                        </td>
                                        <td>
                                          {element.obj.gender == "0"
                                            ? "Male"
                                            : element.obj.gender == "1"
                                            ? "Female"
                                            : "Other"}
                                        </td>
                                        <td>{element.obj.mail_address}</td>
                                        <td>{element.obj.address}</td>
                                        <td>
                                          {element?.obj?.birth_date
                                            ? moment(
                                                element?.obj?.birth_date
                                              ).format("DD-MM-YYYY")
                                            : ""}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                }
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                        <Table
                          style={{
                            textAlign: "left",
                            border: "1px solid gray",
                          }}
                          striped
                          bordered
                          hover
                        >
                          <thead>
                            <th className="p-2">Suspect Details</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {
                                  <Table
                                    style={{
                                      textAlign: "left",
                                      border: "1px solid gray",
                                    }}
                                    striped
                                    bordered
                                    hover
                                  >
                                    <thead>
                                      <tr>
                                        <th> Suspect Name</th>
                                        <th> Mobile Number</th>
                                        <th> Mail Address </th>
                                        <th>Profile Link</th>
                                        <th>Address</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>{element.obj?.suspect_name}</td>
                                        <td>
                                          {element.obj?.suspect_mobile_number}
                                        </td>
                                        <td>
                                          {element.obj?.suspect_mail_address}
                                        </td>
                                        <td>
                                          {/^(ftp|http|https):\/\/[^ "]+$/.test(
                                            element.obj?.suspect_profile_link
                                          ) ? (
                                            <a
                                              target="_blank"
                                              href={`${element.obj?.suspect_profile_link}`}
                                            >
                                              {" "}
                                              <FaLink
                                                style={{
                                                  fontSize: "1.3em",
                                                  marginRight: "5px",
                                                }}
                                              />
                                              <span>Visit Link</span>
                                            </a>
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                        <td>{element.obj?.suspect_address}</td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                }
                              </td>
                            </tr>
                          </tbody>
                        </Table>

                        <Table
                          style={{
                            textAlign: "left",
                            border: "1px solid gray",
                          }}
                          striped
                          bordered
                          hover
                        >
                          <thead>
                            <th className="p-2">Additional Details</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {
                                  <Table
                                    style={{
                                      textAlign: "left",
                                      border: "1px solid gray",
                                    }}
                                    striped
                                    bordered
                                    hover
                                  >
                                    <thead>
                                      <tr>
                                        <th> Police Station</th>
                                        <th> Media Type</th>
                                        <th> Document/Image</th>
                                        <th> Fraud At</th>
                                        <th> Remarks</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {element?.obj?.police_station_details
                                            ?.length
                                            ? element?.obj
                                                ?.police_station_details[0]
                                                ?.police_station
                                            : ""}
                                        </td>
                                        <td>
                                          {element?.obj?.media_platform_details
                                            ?.length
                                            ? element?.obj
                                                ?.media_platform_details[0]
                                                ?.type_name
                                            : ""}
                                        </td>
                                        <td>
                                          {" "}
                                          <img
                                            key={index}
                                            src={
                                              element?.obj
                                                ?.social_media_documents_details
                                                ?.length
                                                ? `${IMAGEMODELURL}/${element?.obj.social_media_documents_details[0].document_path}`
                                                : ImageNotFound
                                            }
                                            style={{
                                              marginLeft: "10px",
                                              height: 90,
                                              width: 90,
                                              borderWidth: 0,
                                              border: "1px solid #007AB1",
                                            }}
                                          />
                                        </td>
                                        <td>
                                          {element?.obj?.fraud_at
                                            ? moment(
                                                element?.obj?.fraud_at
                                              ).format("DD-MM-YYYY")
                                            : ""}
                                        </td>
                                        <td>{element.obj?.remarks}</td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                }
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                  )}
                </>
              ))}
            </tbody>
            <tbody>
              <tr>
                {!tableData.length && (
                  <td colSpan={11}>
                    <div
                      style={{
                        textAlign: "center",
                        padding: "20px",
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "#ff0000",
                        backgroundColor: "#f0f0f0",
                        border: "1px solid #e0e0e0",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                    >
                      NO DATA FOUND
                    </div>
                  </td>
                )}
              </tr>
            </tbody>
          </Table>
          <div className="pt-2 pb-2">
            <Button variant="primary" onClick={setPreviousApi} size="sm">
              PREVIOUS
            </Button>{" "}
            <Button variant="secondary" onClick={setNextApi} size="sm">
              NEXT
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialMedia;
