import React, { useContext } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie';

const AdminRoute = ({children,path}) => {
   
    console.log(path,"path")
    const [cookies, setCookie,removeCookie] = useCookies();
 
    if(cookies.ckUserTypeID=="1"){
   return children
    }
    return <Navigate to={"/admin/socialMedia"} />
}

export default AdminRoute