
import React, { useEffect, useState } from 'react'
import { UNSAFE_ViewTransitionContext, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Modal } from "react-bootstrap";
import Swal from 'sweetalert2';
import { IMAGEMODELURL, CYBER_CELL_URL } from '../../Process'
import UserValidationPage from './ValidateUser';
import { useCookies } from 'react-cookie';
import ValidateModel from './ValidateModel';

const MoneyDeduction = () => {
  const [cookies, setCookie,removeCookie] = useCookies();

    const navigate=useNavigate()
    const currentTime = new Date();

   
     
   let  formattedDate = currentTime.toISOString().slice(0, 16)
  const [modalShow, setModalShow] = useState(false);
   
  const [maxDate,setMaxData]=useState(formattedDate)
    const location = useLocation();
    const [isSubmit,setIsSubmit]=useState(false)
    const [validateExpiry,setValidateExpiry]=useState(true)
    const [formData, setFormData] = useState({
        id:{
            textValue:  "",
            required: false,
        },
        sender_bank_name: {
          textValue:  "",
          required: false,
        },
        sender_account_number: {
          textValue:  "",
          required: false,
        },
        sender_branch_name: {
          textValue:   "",
          required: false,
        },
        sender_ISFC_code: {
          textValue: "",
          required: false,
        },
        receiver_bank_name: {
          textValue:   "",
          required: false,
        },
        receiver_account_number: {
          textValue:   "",
          required: false,
        },
        receiver_branch_name: {
          textValue:   "",
          required: false,
        },
        receiver_ISFC_code: {
          textValue:   "",
          required: false,
        },
        amount: {
          textValue:  "",
          required: false,
        },
        mobile_number: {
          textValue:   "",
          required: false,
        },
        remarks:{
            textValue:   "",
            required: false,
        },
        fraud_at:{
            textValue:   "",
            required: false,
        },
        link_genration_id:{
            textValue:   "",
            required: false,
        }
      });

    const currentUrl = IMAGEMODELURL+location.pathname 
    // console.log(currentTime.toISOString(),"currentUrl")
    useEffect(()=>{
      handleValidateUrl()
      if(cookies.currentValidUser==1){

        setModalShow(false)
    
        }else{
          setModalShow(true)
        }
 
    },[])
    const handleValidateUrl=async()=>{
        const res=await fetch(`${CYBER_CELL_URL}global_link/validate_link`,{
            method:"post",
            headers:{
                "Content-Type":"application/json"
            },

            body:JSON.stringify({
                "link" : currentUrl
            })
        })
        const result=await res.json()
        console.log(result,"result")
        if(result.status==false){
           return  navigate("/")
        }
        if(result?.data[0]?.link_expire_at < currentTime.toISOString() ){
            setValidateExpiry(false)
        }else{
            setValidateExpiry(true)
            handleUserData(result?.data[0]?.uid)
        }
    }
 const handleUserData=async(uuid)=>{
    const res=await fetch(`${CYBER_CELL_URL}money_deduction/get`,{
        method:"post",
        headers:{
            "Content-Type":"application/json"
        },
        body:JSON.stringify({
            "start_date": null,
            "end_date": null,
            "is_pagination" : null,
            "per_page" : null,
            "id" : null,
            "unique_token_id" : uuid
        })
    })

    const result=await res.json()
    console.log(result,"finalResult")
    if(result?.data?.length){
        
        let formattedDateString;
        if(result?.data[0]?.fraud_at){
          const dateString = result?.data[0]?.fraud_at
          const dateObject = new Date(dateString);

           formattedDateString = dateObject.toISOString().slice(0, 16)
        }
  
        setFormData({
            id:{
                textValue:  result.data[0]?.id || "",
                required: false,
            },
            sender_bank_name: {
                textValue:  result.data[0]?.sender_bank_name || "",
                required: false,
              },
              sender_account_number: {
                textValue:  result.data[0]?.sender_account_number || "",
                required: false,
              },
              sender_branch_name: {
                textValue:  result.data[0]?.sender_branch_name  || "",
                required: false,
              },
              sender_ISFC_code: {
                textValue: result.data[0]?.sender_ISFC_code ||"",
                required: false,
              },
              receiver_bank_name: {
                textValue:   result.data[0]?.receiver_bank_name || "",
                required: false,
              },
              receiver_account_number: {
                textValue:  result.data[0]?.receiver_account_number || "",
                required: false,
              },
              receiver_branch_name: {
                textValue:   result.data[0]?.receiver_branch_name || "",
                required: false,
              },
              receiver_ISFC_code: {
                textValue:   result.data[0]?.receiver_ISFC_code || "" ,
                required: false,
              },
              amount: {
                textValue:  result.data[0]?.amount || "",
                required: false,
              },
              mobile_number: {
                textValue:result.data[0]?.mobile_number ||    "",
                required: false,
              },
              remarks:{
                  textValue:result.data[0]?.remarks ||   "",
                  required: false,
              },
              fraud_at:{
                  textValue: formattedDateString || maxDate,
                  required: false,
              },
              link_genration_id:{
                textValue:   result.data[0]?.link_genration_id ||   "",
                required: false,
            }
        })
    }
  

 }
    const handleSubmit=(e)=>{
  
        console.log(formData,"formData")
        e.preventDefault()
        setIsSubmit(true)
        Swal.fire({
          title: "Do you want to save the changes?",
          showCancelButton: true,
          buttonsStyling:false,
    
          confirmButtonText: "Save",
          customClass: {
            confirmButton: 'btn btn-primary me-3', // React-Bootstrap primary button style
            cancelButton: 'btn btn-danger '  // React-Bootstrap secondary button style
          },
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
            
               
                    handleDataInsert()

                
            } else if (result?.isDenied) {
                Swal.fire("Changes are not saved");
            }
          });
    }
    const handleDataInsert=async()=>{
      const dateObject = new Date(formData.fraud_at.textValue);

    const res=await fetch(`${CYBER_CELL_URL}money_deduction/update`,{
        method:"post",
        headers:{
            "Content-Type":"application/json"
        },
        body:JSON.stringify({
            "id" : formData?.id?.textValue, 
            "is_submit" : 1,
            "sender_bank_name" : formData.sender_bank_name.textValue ,
            "sender_account_number" : formData.sender_account_number.textValue ,
            "sender_branch_name" : formData.sender_branch_name.textValue ,
            "sender_ISFC_code" : formData.sender_ISFC_code.textValue ,
            "receiver_bank_name" : formData.receiver_bank_name.textValue ,
            "receiver_account_number" : formData.receiver_account_number.textValue ,
            "receiver_branch_name" : formData.receiver_branch_name.textValue ,
            "receiver_ISFC_code" : formData.receiver_ISFC_code.textValue ,
            "amount" : formData.amount.textValue ,
            "mobile_number" : formData.mobile_number.textValue ,
            "remarks" : formData.remarks.textValue ,
            "fraud_at": `${dateObject.getFullYear()}-${dateObject.getMonth()+1}-${dateObject.getDate()} ${dateObject.getHours()}:${dateObject.getMinutes()}:${dateObject.getSeconds()}`  || maxDate  ,
        })
    }) 
    const result=await res.json()
    if(result.status==true){
      Swal.fire({

        title: "Saved! success",
      
        buttonsStyling:false,
  
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: 'btn btn-primary me-3', // React-Bootstrap primary button style
  
        },
      })
    }else{
      Swal.fire(
        {
            title: `Changes are not saved , ${result.message}`,
            buttonsStyling:false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: 'btn btn-primary me-3', // React-Bootstrap primary button style
      
            },
          
        });
    }
    }
 
      const  handleCancle=()=>{
          Swal.fire({
            title: "Do you want to Cancel the changes?",
      // showCancelButton: true,
      buttonsStyling:false,
      showDenyButton: true,
      denyButtonText: `No`,
      confirmButtonText: "Yes",
      confirmButtonColor : "#007AB1",
      denyButtonColor :"white",
      denyButtonBorder:"1px solid black",
      customClass: {
        confirmButton: 'btn btn-primary me-3', // React-Bootstrap primary button style
        denyButton: 'btn btn-danger '  // React-Bootstrap secondary button style
      },
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                // handleDataInsert()
                setFormData((prev)=>{
                
                
            return     {
                    id:{
                        textValue:  prev.id.textValue,
                        required: false,
                    },
                    sender_bank_name: {
                      textValue:  "",
                      required: false,
                    },
                    sender_account_number: {
                      textValue:  "",
                      required: false,
                    },
                    sender_branch_name: {
                      textValue:   "",
                      required: false,
                    },
                    sender_ISFC_code: {
                      textValue: "",
                      required: false,
                    },
                    receiver_bank_name: {
                      textValue:   "",
                      required: false,
                    },
                    receiver_account_number: {
                      textValue:   "",
                      required: false,
                    },
                    receiver_branch_name: {
                      textValue:   "",
                      required: false,
                    },
                    receiver_ISFC_code: {
                      textValue:   "",
                      required: false,
                    },
                    amount: {
                      textValue:  "",
                      required: false,
                    },
                    mobile_number: {
                      textValue:   prev.mobile_number.textValue,
                      required: false,
                    },
                    remarks:{
                        textValue:   "",
                        required: false,
                    },
                    fraud_at:{
                      textValue:   maxDate,
                      required: false,
                  },
                   link_genration_id:{
                   textValue:   "",
                   required: false,
        }
                 
                  }
                }
                  
                  );
            } 
          });
      }
      const handleInputChange = (e) => {
    let lastInputString = e.target.value[e.target.value.length - 1];

        if (
            e.target.name == "receiver_account_number" ||
            e.target.name == "amount" ||
            e.target.name == "mobile_number" ||
            e.target.name == "sender_account_number" 
      
          ) {
            if (
              e.target.value == "" ||
              (lastInputString.charCodeAt() >= 48 &&
                lastInputString.charCodeAt() <= 57)
            ) {
              setFormData({
                ...formData,
                [e.target.name]: {
                  ...formData[e.target.name],
                  textValue: e.target.value,
                },
              });
            } else {
              return;
            }
            return
          }

        // let lastInputString = e.target.value[e.target.value.length - 1];
     
        setFormData({
          ...formData,
          [e.target.name]: {
            ...formData[e.target.name],
            textValue: e.target.value,
          },
        });
      };
  return (
    // <div className="body-wrapper">
    <div className="container mt-5 mb-5">
        <ValidateModel modalShow={modalShow} setModalShow={setModalShow}  />
        {  cookies.currentValidUser === 1 && <div className="row">
      <div style={{textAlign:"center"}}>
      <h3  style={{ fontSize: "2.4em", fontWeight: "600" }}>
      CYBER CELL SURAT
            </h3>
      <h4  style={{color:"gray", fontSize: "1.8em", fontWeight: "450" }}>
      Finance Fraud - Money Deduction
            </h4>
            </div>
        <div className="col-md-12">
       
            <div className=" py-3 mb-3 " >
              <h5 style={{fontSize:"2em",fontWeight:"400"}}> Registration Form</h5>
            </div>
              <form onSubmit={handleSubmit}>
                <div className="row">
                <div className=" col-md-12 mb-3 border-bottom">
              <h5>Sender Bank Details</h5>
            </div>
                  <div className="col-md-3 mb-3 ">
                    <div className="form-group">
                      <label
                        htmlFor="hotelName"
                        className="form-label fw-semibold"
                      >
                        Sender Bank Name
                        {" "}
                         {/* <span style={{ color: "red" }}>*</span> */}
                      </label>
                      <input
                        onChange={handleInputChange}
                        name="sender_bank_name"
                        value={formData.sender_bank_name.textValue}
                        type="text"
                        className="form-control"
                        placeholder="Sender Bank Name"
                        // style={{border:`1px solid ${(isSubmit && !formData.sender_bank_name.textValue) ? "red" : isSubmit ? "green" : "light-gray" }  `}}

                      ></input>
                      {/* {isSubmit && !formData.sender_bank_name.textValue && (
                        <span className="text-danger">*</span>
                      )} */}
                    </div>
                  </div>
                  <div className="col-md-3 mb-2 ">
                    <div className="form-group">
                      <label
                        htmlFor="hotelName"
                        className="form-label fw-semibold"
                      >
                        Sender Account Number
                         {/* <span style={{ color: "red" }}>*</span> */}
                      </label>
                      <input
                        onChange={handleInputChange}
                        name="sender_account_number"
                        value={formData.sender_account_number.textValue}
                        type="text"
                        className="form-control"
                        placeholder="Sender Account Number"
                        // style={{border:`1px solid ${(isSubmit && !formData.sender_bank_name.textValue) ? "red" : isSubmit ? "green" : "light-gray" }  `}}

                      ></input>
                      {/* {isSubmit && !formData.sender_bank_name.textValue && (
                        <span className="text-danger">*</span>
                      )} */}
         
                    </div>
                  </div>
                  <div className="col-md-3 mb-2 ">
                    <div className="form-group">
                      <label
                        htmlFor="sender_branch_name"
                        className="form-label fw-semibold"
                      >
                        Sender Branch Name
                         {/* <span style={{ color: "red" }}>*</span> */}
                      </label>
                      <input
                        onChange={handleInputChange}
                        name="sender_branch_name"
                        value={formData.sender_branch_name.textValue}
                        type="text"
                        className="form-control"
                        placeholder="Sender Branch Name"
                        // style={{border:`1px solid ${(isSubmit && !formData.sender_bank_name.textValue) ? "red" : isSubmit ? "green" : "light-gray" }  `}}

                      ></input>
                      {/* {isSubmit && !formData.sender_bank_name.textValue && (
                        <span className="text-danger">*</span>
                      )} */}
         
                    </div>
                  </div>
                  <div className="col-md-3 mb-2 ">
                    <div className="form-group">
                      <label
                        htmlFor="sender_ISFC_code"
                        className="form-label fw-semibold"
                      >
                        Sender ISFC Code
                       
                      </label>
                      <input
                        onChange={handleInputChange}
                        name="sender_ISFC_code"
                        value={formData.sender_ISFC_code.textValue}
                        type="text"
                        className="form-control"
                        placeholder="Sender ISFC Code"
                        // style={{border:`1px solid ${(isSubmit && !formData.sender_bank_name.textValue) ? "red" : isSubmit ? "green" : "light-gray" }  `}}

                      ></input>
                      {/* {isSubmit && !formData.sender_bank_name.textValue && (
                        <span className="text-danger">*</span>
                      )} */}
         
                    </div>
                  </div>
                  <div className=" col-md-12 mb-3 mt-3 border-bottom">
              <h5>Receiver Bank Details</h5>
            </div>
                  <div className="col-md-3 mb-3 ">
                    <div className="form-group">
                      <label
                        htmlFor="receiver_bank_name"
                        className="form-label fw-semibold"
                      >
                        Receiver Bank Name
                         {/* <span style={{ color: "red" }}>*</span> */}
                      </label>
                      <input
                        onChange={handleInputChange}
                        name="receiver_bank_name"
                        value={formData.receiver_bank_name.textValue}
                        type="text"
                        className="form-control"
                        placeholder="Receiver Bank Name"
                        // style={{border:`1px solid ${(isSubmit && !formData.receiver_bank_name.textValue) ? "red" : isSubmit ? "green" : "light-gray" }  `}}

                      ></input>
                      {/* {isSubmit && !formData.receiver_bank_name.textValue && (
                        <span className="text-danger">*</span>
                      )} */}
         
                    </div>
                  </div>
                  <div className="col-md-3 mb-2 ">
                    <div className="form-group">
                      <label
                        htmlFor="hotelName"
                        className="form-label fw-semibold"
                      >
                        Receiver Account Number
                         {/* <span style={{ color: "red" }}>*</span> */}
                      </label>
                      <input
                        onChange={handleInputChange}
                        name="receiver_account_number"
                        value={formData.receiver_account_number.textValue}
                        type="text"
                        className="form-control"
                        placeholder="Receiver_account_number"
                        // style={{border:`1px solid ${(isSubmit && !formData.receiver_bank_name.textValue) ? "red" : isSubmit ? "green" : "light-gray" }  `}}

                      ></input>
                      {/* {isSubmit && !formData.receiver_bank_name.textValue && (
                        <span className="text-danger">*</span>
                      )} */}
         
                    </div>
                  </div>
                  <div className="col-md-3 mb-2">
                    <div className="form-group">
                      <label
                        htmlFor="receiver_branch_name"
                        className="form-label fw-semibold"
                      >
                        Receiver Branch Name
                         {/* <span style={{ color: "red" }}>*</span> */}
                      </label>
                      <input
                        onChange={handleInputChange}
                        name="receiver_branch_name"
                        value={formData.receiver_branch_name.textValue}
                        type="text"
                        className="form-control"
                        placeholder="Receiver Branch Name"
                        // style={{border:`1px solid ${(isSubmit && !formData.receiver_bank_name.textValue) ? "red" : isSubmit ? "green" : "light-gray" }  `}}
                      ></input>
                      {/* {isSubmit && !formData.receiver_bank_name.textValue && (
                        <span className="text-danger">*</span>
                      )} */}
                    </div>
                  </div>
                  <div className="col-md-3 mb-2 ">
                    <div className="form-group">
                      <label
                        htmlFor="receiver_ISFC_code"
                        className="form-label fw-semibold"
                      >
                        Receiver ISFC Code
                         {/* <span style={{ color: "red" }}>*</span> */}
                      </label>
                      <input
                        onChange={handleInputChange}
                        name="receiver_ISFC_code"
                        value={formData.receiver_ISFC_code.textValue}
                        type="text"
                        className="form-control"
                        placeholder="Receiver ISFC Code"
                        // style={{border:`1px solid ${(isSubmit && !formData.receiver_bank_name.textValue) ? "red" : isSubmit ? "green" : "light-gray" }  `}}

                      ></input>
                      {/* {isSubmit && !formData.receiver_bank_name.textValue && (
                        <span className="text-danger">*</span>
                      )} */}
         
                    </div>
                  </div>
                  <div className=" col-md-12 mt-3 mb-3 border-bottom">
              <h5>Additional Details</h5>
            </div>
                  <div className="col-md-3 mb-2">
                    <div className="form-group">
                      <label
                        htmlFor="contact"
                        className="form-label fw-semibold"
                      >
                        {" "}
                        Amount <span style={{ color: "red" }}></span>
                      </label>
                      <input
                        onChange={handleInputChange}
                        name="amount"
                        value={formData.amount.textValue}
                        maxLength={10}
                        type="text"
                        className="form-control"
                        placeholder="Enter Amount"
                        // style={{border:`1px solid ${(isSubmit && (!formData.contact.textValue || formData.contact.textValue.length!==10)) ? "red" : isSubmit ? "green" : "light-gray" }  `}}

                      ></input>
                      {/* {isSubmit && !formData.contact.textValue && (
                        <span className="text-danger">*</span>
                      )}
                      {isSubmit && formData.contact.textValue && formData.contact.textValue.length!==10 && <span className='text-danger'>Please Enter Valid Contact No</span>} */}
                    </div>
                  </div>
                  <div className="col-md-3 mb-2">
                    <div className="form-group">
                      <label
                        htmlFor="mobile_number"
                        className="form-label fw-semibold"
                      >
                        {" "}
                    Mobile Number <span style={{ color: "red" }}></span>
                      </label>
                      <input
                      disabled={true}
                        onChange={handleInputChange}
                        name="mobile_number"
                        value={formData.mobile_number.textValue}
                        maxLength={10}
                        type="text"
                        className="form-control"
                        placeholder="Enter Mobile Number "
                        // style={{border:`1px solid ${(isSubmit && (!formData.contact.textValue || formData.contact.textValue.length!==10)) ? "red" : isSubmit ? "green" : "light-gray" }  `}}

                      ></input>
                      {/* {isSubmit && !formData.contact.textValue && (
                        <span className="text-danger">*</span>
                      )}
                      {isSubmit && formData.contact.textValue && formData.contact.textValue.length!==10 && <span className='text-danger'>Please Enter Valid Contact No</span>} */}
                    </div>
                  </div>
                  <div className="col-md-3 mb-2 ">
                    <div className="form-group">
                      <label
                        htmlFor="fraud_at"
                        className="form-label fw-semibold"
                      >
                        Fraud At
                         {/* <span style={{ color: "red" }}>*</span> */}
                      </label>
                      <input
                      // onKeyDown={e.preventDefault()}
                      onKeyDown={(e) => e.preventDefault()}
                        onChange={handleInputChange}
                        name="fraud_at"
                        max={maxDate}
                        value={formData.fraud_at.textValue}
                        type="datetime-local"
                        className="form-control"
                        placeholder="Fraud At"
                        // style={{border:`1px solid ${(isSubmit && !formData.sender_bank_name.textValue) ? "red" : isSubmit ? "green" : "light-gray" }  `}}

                      ></input>
                      {/* {isSubmit && !formData.sender_bank_name.textValue && (
                        <span className="text-danger">*</span>
                      )} */}
                
                    </div>
                  </div>
                  <div className="col-md-3 mb-4 ">
                    <div className="form-group">
                      <label className="form-label fw-semibold">
                      Remarks
                      </label>
                      <textarea
                        onChange={handleInputChange}
                        name="remarks"
                        value={formData.remarks.textValue}
                        style={{ height: "125px"
                        // , border:`1px solid ${(isSubmit && !formData.address.textValue) ? "red" : isSubmit ? "green" : "light-gray" }  ` 
                    }}
                        rows="2"
                        cols="20"
                        placeholder="Enter Remarks"
                        className="form-control"
                      />
                      {/* {isSubmit && !formData.address.textValue && (
                        <span className="text-danger">*</span>
                      )} */}
                   
                    </div>
                  </div>
              
                </div>
                <div>
          <Button className='me-2' disabled={!validateExpiry}  onClick={handleSubmit} variant="primary"  >
            Submit 
          </Button>{" "}
          <Button variant="danger" disabled={!validateExpiry} onClick={handleCancle} >
            Cancel
          </Button>
        </div>
              </form>
         
          </div>
       
    
      </div>}
    </div>
//   </div>
  )
}

export default MoneyDeduction