import React from "react";
import { useCookies } from "react-cookie";
import { NavLink, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import ChangePasswordModal from "./ChangePasswordModal";
import { handleLogout } from "./CommonComponents";

const Header = () => {
  const navigate=useNavigate()
    const [openModel,setOpenModel]=useState(false)
    const [showPasswordID,setShowPasswordID]=useState("")
    const [cookies, setCookie,removeCookie] = useCookies();

    const handleLogout=async ()=>{
         removeCookie("ckUserToken")
         removeCookie("ckUserID")
         removeCookie("ckUserTypeID")

        navigate("/login")
    }
    return (
        <>
       {openModel && <ChangePasswordModal openModel={openModel} setOpenModel={setOpenModel} UserType={cookies.ckUserType} id={cookies.ckUserID} />}
    <header
        className="app-header mb-3"
        style={{ boxShadow: "-1px -1px 10px" }}
      >
        <nav className="navbar navbar-expand-lg navbar-light">
          <ul className="navbar-nav">
            <li className="nav-item d-block d-lg-none">
              <a className="nav-link sidebartoggler ms-n3" id="sidebarCollapse">
                <i className="ti ti-menu-2" />
              </a>
            </li>
            <li className="nav-item d-none d-lg-block">
              <a className="text-nowrap nav-link">
                <img
                  src={require("../Assets/logo1.png")}
                  className="rounded-circle me-2"
                  width={35}
                  height={35}
                  alt="User Avatar"
                />{" "}
                <h2 className="mt-1">CYBER CELL POLICE</h2>
              </a>
            </li>
          </ul>
          <div className="d-block d-lg-none">
            <a className="text-nowrap nav-link">
              <h5>Cyber Cell Police</h5>
            </a>
          </div>
          <button
            className="navbar-toggler p-0 border-0"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="p-2">
              <i className="ti ti-dots fs-7" />
            </span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarNav"
          >
            <div className="d-flex align-items-center justify-content-between px-0 px-lg-8">
              <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-center">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link pe-0"
                    href=""
                    id="drop2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div className="d-flex align-items-center">
                      <div className="user-profile-img">
                        <img
                          src={require("../Assets/logo1.png")}
                          className="rounded-circle"
                          width={35}
                          height={35}
                          alt="User Avatar"
                        />
                      </div>
                    </div>
                  </a>
                  <div
                    className="dropdown-menu content-dd dropdown-menu-end dropdown-menu-animate-up"
                    aria-labelledby="drop2"
                  >
                    <div className="d-flex align-items-center justify-content-between py-3 px-7">
                      <h5 className="mb-0 fs-5 fw-semibold">User Profile</h5>
                    </div>

                    <div className="d-flex align-items-center py-4 px-7 border-bottom">
                      <img
                        src={require("../Assets/logo1.png")}
                        className="rounded-circle"
                        width={100}
                        height={100}
                        alt="User Avatar"
                      />
                    </div>

                    <div className="d-grid py-4 px-7 pt-8">
                      <button
                        onClick={() => handleLogout()}
                        className="btn btn-outline-danger btn-lg"
                      >
                        Log Out
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <aside className="left-sidebar">
        <nav className="pt-2 sidebar-nav scroll-sidebar">
          <ul id="sidebarnav" className="in addFont">
            {/* ============================= */}
            {/* Home */}
            {/* ============================= */}
            <li className="nav-small-cap">
              <i className="ti ti-dots nav-small-cap-icon fs-4" />
              <span className="hide-menu">Home</span>
            </li>
            {/* =================== */}
            {/* Dashboard */}
            {/* =================== */}
            
            {cookies.ckUserTypeID == "1" &&  <div className="sidebar-item">
              <NavLink to="/admin/dashboard" className="sidebar-link">
                <span className="hide-menu">Dashboard</span>
              </NavLink>
            </div>}
          <div className="sidebar-item">
              <NavLink to="/admin/socialMedia" className="sidebar-link">
                <span className="hide-menu">Social Media Fraud</span>
              </NavLink>
            </div>
            {   <div className="sidebar-item">
              <NavLink to="/admin/ramsomware" className="sidebar-link">
                <span className="hide-menu">Spam Call</span>
              </NavLink>
            </div>}
          {   <div className="sidebar-item">
              <NavLink to="/admin/1930" className="sidebar-link">
                <span className="hide-menu">1930 </span>
              </NavLink>
            </div>}
            { cookies.ckUserTypeID == "1" &&  <div className="sidebar-item">
            <a className="sidebar-link has-arrow" href="#" aria-expanded="false">
                    <span className="rounded-3">
                      <i className="ti ti-" />
                    </span>
                    <span className="hide-menu">Reports</span>
                  </a>
            <ul aria-expanded="false" style={{width:"320px"}}  className={`collapse first-level `}>
    <li className="sidebar-item">
              <NavLink to="/admin/mostFraudReport" className="sidebar-link">
                <span className="hide-menu ms-2">Frauds Happening Most</span>
              </NavLink>
              </li>
              <div className="sidebar-item">
              <a className="sidebar-link has-arrow" href="#" aria-expanded="false">
                    <span className="rounded-3">
                      <i className="ti ti-" />
                    </span>
                    <span  className="hide-menu" >Often Scam Report</span>
                  </a>
                  <ul   className={`collapse second-level `}>
                    <li>
                    <NavLink to="/admin/OftenScam" className="sidebar-link">
                <span className="hide-menu">Spam Call Report</span>
              </NavLink>
                    </li>
                    <li>    <NavLink to="/admin/numbersOftenScam" className="sidebar-link">
                <span className="hide-menu">Social Media Scam Report </span>
              </NavLink></li>
                  </ul>
                  </div>

              {/* <NavLink to="/admin/OftenScam" className="sidebar-link">
                <span className="hide-menu">Often Scams Report</span>
              </NavLink> */}
                     <div className="sidebar-item">
              <a className="sidebar-link has-arrow" href="#" aria-expanded="false">
                    <span className="rounded-3">
                      <i className="ti ti-" />
                    </span>
                    <span className="hide-menu">Social Media Fraud Report</span>
                  </a>
                  <ul   className={`collapse second-level `}>
                  <li className="sidebar-item">
              <NavLink to="/admin/socialMediaBasedFraud" className="sidebar-link">
                <span className="hide-menu">Social Media Fraud Report</span>
              </NavLink>
              </li>
              <li className="sidebar-item">
              <NavLink to="/admin/socialMediaBasedType" className="sidebar-link">
                <span className="hide-menu">Social Media Type Report</span>
              </NavLink>
              </li>
              <li className="sidebar-item">
              <NavLink to="/admin/basedOnSocialMediaLink" className="sidebar-link">
                <span className="hide-menu">Social Media Link Report</span>
              </NavLink>
              </li>
                  </ul>
                  </div>
           
              {/* <li className="sidebar-item">
              <NavLink to="/admin/numbersOftenScam" className="sidebar-link">
                <span className="hide-menu">Numbers Often Scam Social Media</span>
              </NavLink>
              </li> */}
           
            </ul>
            </div>}
     
          </ul>
          <div className="unlimited-access hide-menu bg-light-primary position-relative my-7 rounded d-block d-lg-none">
            <div className="d-flex">
              <div className="unlimited-access-title">
                <h6 className="fw-semibold fs-4 mb-6 text-dark w-85">
                  Unlimited Access
                </h6>
                <button className="btn btn-primary fs-2 fw-semibold lh-sm">
                  Signup
                </button>
              </div>
              <div className="unlimited-access-img">
                <img
                  src="../../dist/images/backgrounds/rocket.png"
                  alt="true"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </nav>
      </aside>
    </>
  );
};

export default Header;
