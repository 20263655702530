import "./App.css";
// import Routes from './Routing';
import Header from "./component/Header";
// import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import LoginAuth from "./Route/LoginAuth";
import LoginAccess from "./Route/LoginAccess";

import SocialMedia from "./component/SocialMedia";
import Form1930 from "./component/Form1930";
import Ransomware from "./component/Dashboard";
import MoneyDeduction from "./component/PublicForm/MoneyDeduction";
import Form1930Form from "./component/PublicForm/Form1930";
import SocialMediaFraud from "./component/PublicForm/SocialMediaFraud";
import RansomwareForm from "./component/PublicForm/Ransomware";
import PanelLogin from "./component/Login";
import { useCookies } from "react-cookie";
import LoginAccess2 from "./Route/LoginAccess2";
import MainView from "./component/MainView";
import AdminRoute from "./Route/AdminRoute";
import MostFraudReports from "./component/MostFraudReports";
import OftenScam from "./component/OftenScam";
import SocialMediaBasedFraud from "./component/SocialMediaBasedFraud";
import SocialMediaBasedType from "./component/SocialMediaBasedType";
import BasedOnSocialMediaLink from "./component/BasedOnSocialMediaLink";
import NumbersOftenScam from "./component/NumbersOftenScam";

function App() {
  const [cookies, setCookie, removeCookie] = useCookies();
  console.log(cookies, "final rouyter");
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/form/money_deduction/:id" element={<MoneyDeduction />} />
        <Route path="/form/1930/:id" element={<Form1930Form />} />
        <Route
          path="/form/social_media_fraud/:id"
          element={<SocialMediaFraud />}
        />
        <Route path="/form/ransomware/:id" element={<RansomwareForm />} />
        <Route path="/form/spam_call/:id" element={<RansomwareForm />} />
        <Route
          path="/admin/*"
          element={
            <LoginAuth>
              <Header />
              <Routes>
                <Route path="socialMedia" element={<SocialMedia />} />
                <Route path="1930" element={<Form1930 />} />
                <Route path="ramsomware" element={<Ransomware />} />
                <Route path="dashboard" element={<AdminRoute ><MainView /></AdminRoute>} />
                <Route path="mostFraudReport" element={<AdminRoute ><MostFraudReports /></AdminRoute>} />
                <Route path="oftenScam" element={<AdminRoute ><OftenScam /></AdminRoute>} />
                <Route path="socialMediaBasedFraud" element={<AdminRoute ><SocialMediaBasedFraud /></AdminRoute>} />
                <Route path="socialMediaBasedType" element={<AdminRoute ><SocialMediaBasedType /></AdminRoute>} />
                <Route path="basedOnSocialMediaLink" element={<AdminRoute ><BasedOnSocialMediaLink /></AdminRoute>} />
                <Route path="numbersOftenScam" element={<AdminRoute ><NumbersOftenScam /></AdminRoute>} />
            </Routes>
            </LoginAuth>
          }
        />
<Route path="/login" element={<PanelLogin />} />


        
        {/* {!cookies.ckUserID ? (
          <Route
            path="/login"
            element={
              <LoginAccess2>
                <PanelLogin />
              </LoginAccess2>
            }
          />
        ) : (
          <Route
            path="/login"
            element={
              <LoginAccess>
                <SocialMedia />
              </LoginAccess>
            }
          />
        )} */}
        {/* <Route path='/login' element={!cookies.ckUserID ? <PanelLogin />  :  <Navigate to={"/admin/socialMedia"}  />} />  */}
      </Routes>
      <Routes></Routes>
    </BrowserRouter>
  );
}

export default App;
