import React, { useState } from 'react';
import styled from 'styled-components';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput
}
  from 'mdb-react-ui-kit';
import { RotatingLines } from 'react-loader-spinner';
import Swal from 'sweetalert2';
import { CYBER_CELL_URL } from '../../Process';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
const Select = styled.select`
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #3498db; /* Blue border */
  border-radius: 5px;
`;

const Label = styled.label`
  font-size: 14px;
  margin-bottom: 5px;
  font-weight:600;
  
`;
function PanelLogin() {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies();
  const [userType, setUserType] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    removeCookie("ckUserID")
    removeCookie("ckUserToken")
    removeCookie("ckUserTypeID")
  }, [])
  const handleLogin = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      console.log(userType, userName, password);
      const res = await fetch(`${CYBER_CELL_URL}/user/user_login`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: userName,
          password: password,
        }),
      });
      const result = await res.json();

      // if(result){
      //   //status check 
      //   if(result.data){
      //     //Start
      //     if(result.data.length > 0){
            
      //     }else{
      //       //Print Message
      //     }
      //   }else{
      //   //Something Went Wrong.
      //   }
      // }else{
      //   //Something Went Wrong.
      // }


      //data = null
      // data = undefined
      // data  = [{}]
      //data = []
        if (result.status == true) {

          if(!result?.data[0]?.usertype_id){
            Swal.fire({
              title: "We can not identify your role, please co-ordinate with admin",
              icon: "error",
              timer: 1500,
            });
            setIsLoading(false);
            return 
          }
        Swal.fire({
          title: "Successfully Logged In!",
          icon: "success",
          timer: 1500,
        });
        console.log(result, "result");
        let currentUserTypeID=result.data[0].usertype_id
        setCookie("ckUserID", result.data[0].id);
        setCookie("ckUserToken", result.user_login_info_data[0].token);
        setCookie("ckUserTypeID", currentUserTypeID)
        if(currentUserTypeID=="1"){
          navigate("/admin/dashboard");

        }else if(currentUserTypeID=="2"){
          navigate("/admin/socialMedia");
        }
      } else {
        Swal.fire({
          title: result.message ,
          icon: "error",
          timer: 1500,
        });
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      Swal.fire({
        title: "Facing Some Technical Issue , Please Try Again later",
        icon: "error",
        timer: 1500,
      });
      console.log(err, "err");
    }
  };
  const handlePhoneNumber = (e) => {
    setUserName(e.target.value);
  };
  if (isLoading) {
    return (
      <div
        style={{
          position: "absolute",
          zIndex: "6",
          left: "700px",
          top: "300px",
        }}
      >
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="200"
          height="200"
          visible={true}
        />
      </div>
    );
  }
  return (
    <div className="body-wrapper">
      <MDBContainer style={{ boxShadow: "2px 3px 15px", textAlign: "left", border: "1px solid gray", marginTop: "6rem", marginBottom: '3rem' }} className="  gradient-form">

        <div style={{ display: "flex", flexWrap: "wrap" }}>

          <div style={{ width: "38rem" }} className=" me-5 mb-5 mt-4">
            <div className="d-flex flex-column ms-5 ">

              <div className="text-center">
                <img src={require("../../Assets/logo.png")}
                  style={{ width: '145px' }} alt="logo" />
                <h4 className="mt-1 mb-4 pb-1">Cyber Cell Surat</h4>
              </div>
              <Label >
                UserName
              </Label>
              <MDBInput type="text"
                className="mb-3"
                maxLength={10}
                value={userName}
                onChange={handlePhoneNumber}
                placeholder="Enter Username"
                required />
              <Label >
                Password
              </Label>
              <MDBInput type={showPassword ? "text" : "password"}
                // type="password"
                className="mb-4"
                style={{ position: "relative" }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
                required />

              <div className="text-center pt-1 mb-5 pb-1">
                <MDBBtn type={showPassword ? 'text' : 'password'}

                  style={{ position: "relative" }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password"
                  required onClick={handleLogin} className="mb-4  w-100 gradient-custom-2">Log In</MDBBtn>

              </div>
            </div>

          </div>

          <div style={{ width: "39.8rem" }}>
            <img src={require("../../Assets/CyberBanner.jpg")} style={{ margin: "auto", width: "100%", height: "100%" }} alt="" />
          </div>

        </div>

      </MDBContainer>
    </div>
  );
}

export default PanelLogin;
