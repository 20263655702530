import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import styled from "styled-components";
import "react-phone-input-2/lib/style.css";
import { CookieExpiryTime, IMAGEMODELURL, CYBER_CELL_URL } from "../../Process";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useCookies } from "react-cookie";
const Container = styled.div`
  max-width: 600px;
  min-height: 300px;
  margin: 10rem auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;

  h2 {
    margin-bottom: 20px;
  }

  label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
  }

  input {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  button {
    background-color: #4caf50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;

    &:hover {
      background-color: #45a049;
    }
  }
`;

const UserValidationPage = ({ setModalShow }) => {
    const [isResendAllowed, setResendAllowed] = useState(true);
  const [cookies, setCookie, removeCookie] = useCookies();
  const [isSubmit,setIsSubmit]=useState(false)
  const navigate = useNavigate();
  const [expireTime, setExpireTime] = useState();
  const currentTime = new Date();
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [userUid, setUserUid] = useState("");
  const location = useLocation();
  const [isOtpSent, setIsOtpSent] = useState(false);
  console.log(location, "location");
  useEffect(() => {
    handleValidateLink();
  }, []);
  const setCookieWithExpiration = (currentID) => {
    const expirationDate = new Date();
    expirationDate.setTime(
      expirationDate.getTime() + CookieExpiryTime * 60 * 1000
    ); // 10 minutes in milliseconds
    setCookie("currentValidUser", currentID, { expires: expirationDate });
  };
  const currentUrl = IMAGEMODELURL + location.pathname;
  const handleGenerateOTP = () => {
    // Logic to generate and send OTP

    // Disable resend for 1 minute
    setResendAllowed(false);
    setTimeout(() => {
      setResendAllowed(true);
    }, 60000); // 1 minute in milliseconds
  };
  const handleValidateLink = async () => {
    try {
      const res = await fetch(`${CYBER_CELL_URL}global_link/validate_link`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          link: currentUrl,
        }),
      });
      const result = await res.json();
      console.log(result, "result");
      setUserUid(result?.data[0]?.uid);
    } catch (err) {
      console.log(err, "err");
    }
  };

  const handleSendOtp = async () => {
    setIsSubmit(true)
    if(!isResendAllowed){
        Swal.fire({
            title:`Cannot send OTP for next one minute`,
            customClass: {
                title: 'valid-title-class'
            }
        });
        return
    }
    try {
    const messageRes =await Swal.fire({
      title: "Are you sure?",
      text: `OTP will be send to - ${mobileNumber}` ,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes"
    })
        /* Read more about isConfirmed, isDenied below */
        if (messageRes?.isDismissed) {
            // setResendAllowed()
          return
        }
      const res = await fetch(`${CYBER_CELL_URL}global_link/sendOTP`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          uid: userUid,
          mobile_number: mobileNumber,
        }),
      });
      const result = await res.json();
      if (result.status === false) {
        await Swal.fire({
            title:result.message,
            customClass: {
                title: 'valid-title-class', 
            }
            });
        return;
      }
      if(result.status==true){
      console.log(result, "result");
      setExpireTime(result.data[0].link_expire_at);
       Swal.fire({
        title: "OTP Sent Successfully!",
        icon: "success",
        timer: 1500,
      });
      handleGenerateOTP()
      setIsOtpSent(true);
    }
    } catch (err) {
      console.log(err);
    }

  };

  const handleVerifyOtp = async () => {
    // if (currentTime.toISOString() > expireTime) {
    //   await Swal.fire({
    //     title: "OTP Expired!!",
    //     icon: "error",
    //     timer: 1500,
    //   });
    //   return;
    // }
    try {
      const res = await fetch(`${CYBER_CELL_URL}global_link/verifyOTP`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          uid: userUid,
          mobile_number: mobileNumber,
          otp: otp,
        }),
      });
      const result = await res.json();
      console.log(result, "result");
      if (result.status == true) {
        Swal.fire({
          title: "OTP Verified Successfully!!",
          icon: "success",
          timer: 1500,
        });
        console.log(result.data, "result.data");
        setCookieWithExpiration(result?.data[0]?.link_type);
         
        setModalShow(false);
      }
      else if(result.status == false){
        if(otp){
        Swal.fire({
            title: "Invalid OTP!!",
            icon: "error",
            timer: 1500,
          })}
          else{
            Swal.fire(`Please Enter OTP`);
          }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleInputChange = (e) => {
    let lastInputString = e.target.value[e.target.value.length - 1];
    if (
      e.target.value == "" ||
      (lastInputString.charCodeAt() >= 48 && lastInputString.charCodeAt() <= 57)
    ) {
      setMobileNumber(e.target.value);
    }
  };
  const handleOtpChange=(e)=>{
    let lastInputString = e.target.value[e.target.value.length - 1];
    if (
        e.target.value == "" ||
        (lastInputString.charCodeAt() >= 48 && lastInputString.charCodeAt() <= 57)
      ) {
        setOtp(e.target.value);
      }
  }
  return (
    <Container>
      <img
        src={require("../../Assets/logo.png")}
        height={"150px"}
        width={"150px"}
        style={{ borderRadius: "20%", marginBottom: "20px" }}
        alt=""
      />
      <label className="fs-5" htmlFor="mobileNumber">
        Enter Mobile Number:
      </label>
      <div style={{ marginTop: "20px", width: "55%",margin:"auto" }} >
        <input
          disabled={isOtpSent}
          onChange={handleInputChange}
          value={mobileNumber}
          maxLength={10}
          type="text"
          className="form-control "
          placeholder="Enter Mobile Number "
          // style={{border:`1px solid ${(isSubmit && (!formData.contact.textValue || formData.contact.textValue.length!==10)) ? "red" : isSubmit ? "green" : "light-gray" }  `}}
        />
        {/* <span style={{color:"red" ,marginTop:"-20px"}}>Please Enter Mobile Number</span> */}
      </div>
      {!isOtpSent ? (
        <button
          style={{ marginTop: "10px", width: "55%" }}
          onClick={handleSendOtp}
        >
          Send OTP
        </button>
      ) : (
        <div>
          <label className="fs-5" style={{ marginTop: "20px" }} htmlFor="otp">
            Enter OTP:
          </label>
          <input
         
            style={{ width: "55%", margin: "auto", display: "block" }}
            type="text"
            maxLength={6}
            id="otp"
            value={otp}
            onChange={handleOtpChange}
            placeholder="Enter OTP"
          />
          <button
            style={{ marginTop: "20px", width: "55%" }}
            onClick={handleVerifyOtp}
          >
            Verify OTP
          </button>
          <div style={{ marginBottom: "20px" }}>
            {" "}
            <span
              onClick={() => {
                handleSendOtp();
              }}
              
              style={{
                color: "red",
                cursor: "pointer",
                width: "55%",
                float: "right",
                marginTop:"0.3em",
                marginRight: "1em",
                fontSize: "1.1em",
              }}
            >
              Resend OTP
            </span>
          </div>
        </div>
      )}
    </Container>
  );
};

export default UserValidationPage;
