import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { useCookies } from 'react-cookie';

const LoginAuth = ({children,path}) => {
    console.log(path,"path")
    const [cookies, setCookie,removeCookie] = useCookies();
 
    if(!cookies.ckUserID){
        removeCookie("ckUserID")
        removeCookie("ckUserToken")
        removeCookie("ckUserTypeID")
      
 
   return <Navigate to={"/login"} />
    }
    return children
}

export default LoginAuth